<h2 mat-dialog-title>Edit Credits Order</h2>
<mat-dialog-content class="mat-typography">
<div>
    <mat-form-field appearance="fill">
        <mat-label>Transaction ID</mat-label>
        <input matInput name="id" [(ngModel)]="this.firebaseService.selectedCredit.id">
    </mat-form-field>
    <mat-form-field appearance="fill" style="margin-left: 7px;">
        <mat-label>First Name</mat-label>
        <input matInput name="firstName" [(ngModel)]="this.firebaseService.selectedCredit.firstName">
    </mat-form-field>
</div>    
<div>    
    <mat-form-field appearance="fill">
        <mat-label>Paternal Last Name</mat-label>
        <input matInput name="lastNameOne" [(ngModel)]="this.firebaseService.selectedCredit.lastNameOne">
    </mat-form-field>
    <mat-form-field appearance="fill" style="margin-left: 7px;">
        <mat-label>Maternal Last Name</mat-label>
        <input matInput name="lastNameTwo" [(ngModel)]="this.firebaseService.selectedCredit.lastNameTwo">
    </mat-form-field>    
</div>
<div>
    <mat-form-field appearance="fill">
        <mat-label>Email</mat-label>
        <input matInput name="email" [(ngModel)]="this.firebaseService.selectedCredit.email">
    </mat-form-field>
    <mat-form-field appearance="fill" style="margin-left: 7px;">
        <mat-label>RUA</mat-label>
        <input matInput name="rua" [(ngModel)]="this.firebaseService.selectedCredit.rua">
    </mat-form-field>
</div>
<div>
    <mat-form-field appearance="fill">
        <mat-label>Phone</mat-label>
        <input matInput name="mobile" [(ngModel)]="this.firebaseService.selectedCredit.mobile">
    </mat-form-field>
    <mat-form-field appearance="fill" style="margin-left: 7px;">
        <mat-label>Total</mat-label>
        <input matInput name="total" [(ngModel)]="this.firebaseService.selectedCredit.total">
    </mat-form-field>  
</div>
<div>    
    <mat-form-field appearance="fill">
        <mat-label>Net Total</mat-label>
        <input matInput name="netTotal" [(ngModel)]="this.firebaseService.selectedCredit.netTotal">
    </mat-form-field>
    <mat-form-field appearance="fill" style="margin-left: 7px;">
        <mat-label>IVU Amount</mat-label>
        <input matInput name="ivuAmount" [(ngModel)]="this.firebaseService.selectedCredit.ivuAmount">
    </mat-form-field>    
</div>
<div>    
    <mat-form-field appearance="fill">
        <mat-label>Credits</mat-label>
        <input matInput name="credits" [(ngModel)]="this.firebaseService.selectedCredit.credits">
    </mat-form-field>
    <mat-form-field appearance="fill" style="margin-left: 7px;">
        <mat-label>Credits FIFO Balance</mat-label>
        <input matInput name="creditsFIFOBalance" [(ngModel)]="this.firebaseService.selectedCredit.creditsFIFOBalance">
    </mat-form-field>    
</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial (click)="onSaveEditForm()">Save</button>
</mat-dialog-actions>