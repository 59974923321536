<mat-toolbar color="primary">
  <span>Microjuris Gateway - MJCLE</span>
  <!-- <span><img src="../assets/logobeta04_2.png"/></span> -->
  <span class="example-fill-remaining-space"></span>

  <span>Welcome, {{user?.email}} | <button mat-stroked-button (click)="logout()">Log Out</button></span>
</mat-toolbar>

<mat-tab-group>
    <mat-tab label="Customers"> <app-customers></app-customers> </mat-tab>
    <mat-tab label="Credit Transactions"> <app-credits></app-credits> </mat-tab>
    <mat-tab label="Course Requests"> <app-courserequests></app-courserequests> </mat-tab>
    <mat-tab label="Courses"> <app-courses></app-courses> </mat-tab>
    <mat-tab label="Users"> <app-users></app-users> </mat-tab>
    <mat-tab label="Digital Chalk History"> <app-digitalchalk></app-digitalchalk> </mat-tab>
    <mat-tab label="Reports"> <app-reports></app-reports> </mat-tab>
</mat-tab-group>


<div class="footer">
  <p>© 2022 Microjuris Gateway - MJCLE</p>
</div>