import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FirebaseService} from '../services/firebase.service';

@Component({
  selector: 'app-digitalchalk',
  templateUrl: './digitalchalk.component.html',
  styleUrls: ['./digitalchalk.component.scss']
})
export class DigitalchalkComponent implements OnInit {

  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;

  years: any;
  startYear: any;

  selectedMonth: any;
  selectedYear: any;
  
  courseRegistrations: any;
  dataAny: any;

  objectLength: any;

  //private apiUrl = `https://api.digitalchalk.com/dc/api/v5/registrations?limit=14000&status=passed&offset=0&endDate=${this.selectedYear}-${this.selectedMonth}`;

  constructor(
    private _formBuilder: FormBuilder,
    private http: HttpClient,
    public dialog: MatDialog,
    public firebaseService: FirebaseService,
  ) { }

  ngOnInit(): void {
    this.getYears();

    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
  }

  getYears(){

    //this.years = function(startYear) {
      var currentYear = new Date().getFullYear(), years = [];
      this.startYear = this.startYear || 1980;  
      while ( this.startYear <= currentYear ) {
          years.push(this.startYear++);
          this.years = years.sort((a, b) => b - a);;
      }
      //console.log("this.years: ", this.years)
      return years;
    //}
   
    //console.log( this.years(2019-20));

  }

  openSyncConfirmDialog() {
    const dialogRef = this.dialog.open(RegistrationsSyncConfirmDialog);
    //this.firebaseService.selected = customer;

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      //window.location.reload();
    });
  }

  async syncRegistrations(selectedMonth: any,selectedYear: any){
    //console.log("syncRegistrations() Function triggered!")
    let url = `https://api.digitalchalk.com/dc/api/v5/registrations?limit=14000&status=passed&offset=0&endDate=${this.selectedYear}-${this.selectedMonth}`;

    const headers = new HttpHeaders().set('Host', 'api.digitalchalk.com').set('Content-Type', 'application/json').set('Authorization', 'Bearer 3f970204-f2e9-4c45-add4-a24e9911816b');

    return this.http.get(url,{headers})
                    .toPromise()
                    .then( res => {
                      //console.log(res)
                      let data = [];
                      let dataLength: any;
                      data.push(res);
                      //console.log("data: ",data)
                      this.dataAny = data;

                        this.courseRegistrations = this.dataAny;

                        this.courseRegistrations.map (a => {

                          //console.log("Length: ", a.results.length.toString())

                          this.objectLength = a.results.length.toString();

                          a.results.forEach((registrations) => {
                          registrations.id = registrations.id.toString();
                          registrations.userId = registrations.userId.toString();
                          registrations.offeringId = registrations.offeringId.toString();
                          registrations.title = registrations.title.toString();
                          registrations.status = registrations.status.toString();
                          registrations.grade = registrations.grade.toString();
                          registrations.createdDate = registrations.createdDate.toString();
                          registrations.beginDate = registrations.beginDate.toString(); //PUT THIS BACK!!!!!!!!
                          registrations.lastActiveDate = registrations.lastActiveDate.toString();
                          registrations.endDate = registrations.endDate.toString();
                  
                          var dataRegistrations = {
                              registrationID: registrations.id,
                              courseTitle: registrations.title,
                              digitalChalkUserID: registrations.userId,
                              courseID: registrations.offeringId,
                              registrationStatus: registrations.status,
                              courseGrade: registrations.grade,
                              createdDate: registrations.createdDate,
                              beginDate: registrations.beginDate,
                              lastActiveDate: registrations.lastActiveDate,
                              endDate: registrations.endDate,
                              //FIFOCostPerCredit: ""
                          };
                  
                          //console.log("ID:", registrations.id);
                          //console.log("Title:", registrations.title);
                          //console.log(`Data: ${JSON.stringify(registrations)}`);
                          //console.log(`Data: ${JSON.stringify(dataRegistrations)}`);

                          let syncDataRegistrations: any;
                          syncDataRegistrations = dataRegistrations;

                          //console.log("syncDataRegistrations:",syncDataRegistrations)
                          
                          if((this.objectLength !== null ||this.objectLength !== undefined || this.objectLength !== "")){
                            return this.firebaseService.addRegistrations(syncDataRegistrations);
                          }
                        });
                      });
                      if((this.objectLength !== null ||this.objectLength !== undefined || this.objectLength !== "")){

                        this.openSyncConfirmDialog();

                      }
                    //})//
                    },
                    err => {
                      console.log("Error occured");
                    })
                    .catch(err => {
                      console.log(err)
                    })
  }

  // getRegistrarionsData(){
  //   this.syncRegistrations.subscribe(data => {
  //     console.log(data);
  //     this.data = data
  // }

}

//--------------------------------------------------------Add Credits Confirmation Dialog------------------------------------

@Component({
  selector: 'digitalchalk-sync-info',
  templateUrl: 'digitalchalk-sync-info.html',
})
export class RegistrationsSyncConfirmDialog {

  constructor(
    public dialog: MatDialog,
  ) { }

} 