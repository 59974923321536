<h2 mat-dialog-title>Add New Course</h2> 
<mat-dialog-content class="mat-typography">
<div>
    <mat-form-field appearance="fill">
        <mat-label>Course ID</mat-label>
        <input matInput name="courseID" [(ngModel)]="this.firebaseService.newCourse.courseID">
    </mat-form-field>
    <mat-form-field appearance="fill" style="margin-left: 7px;">
        <mat-label>Course Name</mat-label>
        <input matInput name="courseName" [(ngModel)]="this.firebaseService.newCourse.courseName">
    </mat-form-field>
</div>    
<div>
    <mat-form-field appearance="fill">
        <mat-label>Course Credit</mat-label>
        <input matInput name="courseCredit" [(ngModel)]="this.firebaseService.newCourse.courseCredit">
    </mat-form-field>      
    <mat-form-field appearance="fill" style="margin-left: 7px;">
        <mat-label>Course Ref</mat-label>
        <input matInput name="courseRef" [(ngModel)]="this.firebaseService.newCourse.courseRef">
    </mat-form-field>  
</div>
<div>
    <mat-form-field appearance="fill">
        <mat-label>Start Date</mat-label>
        <input matInput name="startDate" [(ngModel)]="this.firebaseService.newCourse.startDate">
    </mat-form-field>
    <mat-form-field appearance="fill" style="margin-left: 7px;">
        <mat-label>End Date</mat-label>
        <input matInput name="endDate" [(ngModel)]="this.firebaseService.newCourse.endDate">
    </mat-form-field>
</div>
<div>    
    <mat-form-field appearance="fill">
        <mat-label>Course Status</mat-label>
        <mat-select name="courseStatus" [(ngModel)]="this.firebaseService.newCourse.courseStatus">
            <mat-option value="Active">Active</mat-option>
            <mat-option value="Inactive">Inactive</mat-option>
        </mat-select>
    </mat-form-field>     
    <mat-form-field appearance="fill" style="margin-left: 7px;">
        <mat-label>Professor Name</mat-label>
        <input matInput name="professorName" [(ngModel)]="this.firebaseService.newCourse.professorName">
    </mat-form-field>    
</div>
<div>
    <mat-form-field appearance="fill">
        <mat-label>Horas General</mat-label>
        <input matInput name="horasGeneral" [(ngModel)]="this.firebaseService.newCourse.horasGeneral">
    </mat-form-field> 
    <mat-form-field appearance="fill" style="margin-left: 7px;">
        <mat-label>Horas Etica</mat-label>
        <input matInput name="horasEtica" [(ngModel)]="this.firebaseService.newCourse.horasEtica">
    </mat-form-field>     
</div>
<div>
    <mat-form-field appearance="fill">
        <mat-label>Horas Notaria</mat-label>
        <input matInput name="horasNotaria" [(ngModel)]="this.firebaseService.newCourse.horasNotaria">
    </mat-form-field> 
    <mat-form-field appearance="fill" style="margin-left: 7px;">
        <mat-label>Course Activation Type</mat-label>
        <mat-select name="activationType" [(ngModel)]="this.firebaseService.newCourse.activationType">
            <mat-option value="Credits">Credits</mat-option>
            <mat-option value="Individual">Individual</mat-option>
        </mat-select>
    </mat-form-field>     
</div>
<div>
    <mat-form-field appearance="fill">
        <mat-label>Topic</mat-label>
        <textarea matInput name="topic" [(ngModel)]="this.firebaseService.newCourse.topic"></textarea>
    </mat-form-field>     
</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial (click)="onSaveAddForm()">Save</button>
</mat-dialog-actions>