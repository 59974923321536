import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard as AuthGuardService } from './guards/auth.guard';

import { LoginComponent } from '../app/login/login.component';
import { HomeComponent } from '../app/home/home.component';
import { CustomersComponent } from '../app/customers/customers.component';
import { CreditsComponent } from '../app/credits/credits.component';
import { CourserequestsComponent } from '../app/courserequests/courserequests.component';
import { CoursesComponent } from '../app/courses/courses.component';
import { UsersComponent } from '../app/users/users.component';
import { CustomerEditDialog } from '../app/customers/customers.component';
import { CreditsEditDialog } from '../app/credits/credits.component';
import { CourserequestEditDialog } from '../app/courserequests/courserequests.component';
import { CoursesEditDialog } from '../app/courses/courses.component';
import { UserEditDialog } from '../app/users/users.component';
import { CustomersEditDialogComponent } from '../app/customers-edit-dialog/customers-edit-dialog.component';
import { CustomerNewDialog } from '../app/customers/customers.component';
import { CustomerAddConfirmDialog } from '../app/customers/customers.component';
import { CustomerTransactions } from '../app/customers/customers.component';
import { CustomerCourses } from '../app/customers/customers.component';
import { CustomerAddCreditsDialog } from '../app/customers/customers.component';
import { CustomerCoursesHistory }from '../app/customers/customers.component';
import { CourserequestNewDialog } from '../app/courserequests/courserequests.component';
import { CourseRequestAddConfirmDialog } from '../app/courserequests/courserequests.component';
import { CourseNewDialog } from '../app/courses/courses.component';
import { CourseAddConfirmDialog } from '../app/courses/courses.component';
import { UserNewDialog } from '../app/users/users.component';
import { UserAddConfirmDialog } from '../app/users/users.component';
import { CustomerAddCreditsConfirmDialog } from '../app/customers/customers.component';
import { RegistrationsSyncConfirmDialog } from '../app/digitalchalk/digitalchalk.component';
import { SupremoDialog } from '../app/reports/reports.component';
import { RegaliaDialog } from '../app/reports/reports.component';
import { EjectConfirmDialog } from '../app/customers/customers.component';
import { ResetPasswordDialog } from '../app/login/login.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuardService] },
  { path: 'customers', component: CustomersComponent, canActivate: [AuthGuardService] },
  { path: 'credits', component: CreditsComponent, canActivate: [AuthGuardService] },
  { path: 'courserequests', component: CourserequestsComponent, canActivate: [AuthGuardService] },
  { path: 'courses', component: CoursesComponent, canActivate: [AuthGuardService] },
  { path: 'users', component: UsersComponent, canActivate: [AuthGuardService] },
  { path: 'customeredit', component: CustomerEditDialog },
  { path: 'creditsedit', component: CreditsEditDialog },
  { path: 'requestsedit', component: CourserequestEditDialog },
  { path: 'coursesedit', component: CoursesEditDialog },
  { path: 'useredit', component: UserEditDialog },
  { path: 'customereditdialog', component: CustomersEditDialogComponent },
  { path: 'customernew', component: CustomerNewDialog },
  { path: 'customeraddconfirm', component: CustomerAddConfirmDialog },
  { path: 'customertransactions', component: CustomerTransactions },
  { path: 'customercourses', component: CustomerCourses },
  { path: 'customeraddcredits', component: CustomerAddCreditsDialog },
  { path: 'customercourseshistory', component: CustomerCoursesHistory },
  { path: 'newcoursereq', component: CourserequestNewDialog },
  { path: 'coursereqaddconfirm', component: CourseRequestAddConfirmDialog },
  { path: 'editcourse', component: CourseNewDialog },
  { path: 'courseaddconfirm', component: CourseAddConfirmDialog },
  { path: 'newuser', component: UserNewDialog },
  { path: 'useraddconfirm', component: UserAddConfirmDialog },
  { path: 'creditsaddconfirm', component: CustomerAddCreditsConfirmDialog },
  { path: 'syncconfirm', component: RegistrationsSyncConfirmDialog },
  { path: 'supremoreport', component: SupremoDialog },
  { path: 'regaliareport', component: RegaliaDialog },
  { path: 'ejectconfirm', component: EjectConfirmDialog },
  { path: 'resetpassword', component: ResetPasswordDialog }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
