<body>
<div class="main-div">
<div class="example-card">
<mat-toolbar color="primary" style="overflow-wrap: break-word;word-wrap: break-word;">
        <span>Welcome to Microjuris Gateway - MJCLE</span>
      
        <!-- This fills the remaining space of the current row -->
        <!-- <span class="example-fill-remaining-space"></span>
      
        <span></span> -->
      </mat-toolbar>
      
      <div class="example-container">
        <mat-card>
        <mat-card-header>
          <mat-card-title>Login to get started:</mat-card-title>
        </mat-card-header>    
        <!-- <br> -->
        <mat-card-content>
        <form class="form" [formGroup]="validations_form" (ngSubmit)="tryLogin(validations_form.value)">
        <mat-form-field appearance="fill" style="width: 200px;">
          <mat-label>Email</mat-label>
          <!-- <input matInput> -->
          <input matInput placeholder="user@example.com" formControlName="email" required>
          <mat-icon matSuffix>mail</mat-icon>
          <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>
        </mat-form-field>
        <div class="validation-errors">
            <ng-container *ngFor="let validation of validation_messages.email">
              <div class="error-message"
                   *ngIf="validations_form.get('email').hasError(validation.type) && (validations_form.get('email').dirty || validations_form.get('email').touched)">
                {{ validation.message }}
              </div>
            </ng-container>
          </div>
        <!-- <br> -->
        <mat-form-field appearance="fill" style="width: 200px;">
          <mat-label>Password</mat-label>
          <!-- <input matInput> -->
          <input matInput [type]="hide ? 'password' : 'text'" formControlName="password">
          <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
          <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field>
        <div class="validation-errors">
            <ng-container *ngFor="let validation of validation_messages.password">
              <div class="error-message"
                   *ngIf="validations_form.get('password').hasError(validation.type) && (validations_form.get('password').dirty || validations_form.get('password').touched)">
                {{ validation.message }}
              </div>
            </ng-container>
          </div>
          <div> <!-- style="margin-top:7px;"-->
          <button mat-button mat-raised-button color="primary" type="submit" [disabled]="!validations_form.valid">Login</button>
          <button mat-button mat-raised-button color="primary" (click)="openResetPasswordDialog()">Recover Password</button> <!--style="margin-left:7px;-->
          </div>
          <br/>
          <p class="img-container" style="float:right;">
            <img src="../assets/logobeta04_2.png"/>
          </p>
        </form>  
        </mat-card-content>
        <!-- <mat-card-actions>
          <button mat-raised-button color="primary" type="submit" [disabled]="!validations_form.valid">Login</button>
          <button mat-raised-button color="primary">Recover Password</button>
        </mat-card-actions> -->
        <br>
        <label class="error-message">{{errorMessage}}</label>
        </mat-card>
        <!-- <mat-form-field appearance="fill">
          <mat-label>Select</mat-label>
          <mat-select>
            <mat-option value="option">Option</mat-option>
          </mat-select>
        </mat-form-field> -->
        <br>
        <div style="margin:30px;">
          <ngb-alert *ngIf="!!authService.successEmailSent" (click)="authService.successEmailSent = false" [dismissible]="true" type="success">
            <span style="color:#228B22;"><strong>Success!</strong> Email sent successfully.</span>
          </ngb-alert>
          <ngb-alert *ngIf="authService.showUnauthorizedAlert" (click)="closeAlert()" [dismissible]="true" type="danger">
            <span style="color:#B22222"><strong>Alert!</strong> {{ !authService.errorMsg? 'Permission denied.': authService.errorMsg}}</span>
          </ngb-alert>
        </div>
        <!-- <mat-form-field appearance="fill">
          <mat-label>Textarea</mat-label>
          <textarea matInput></textarea>
        </mat-form-field> -->
      </div>
      </div>    
</div>
</body>
      
      <!-- <footer layout="row" class="md-padding">
        <div>
          <p>© 2020 Microjuris Gateway - MJCLE</p>
        </div>
      </footer> -->
      
      <div class="footer">
        <p>© 2022 Microjuris Gateway - MJCLE</p>
      </div>
