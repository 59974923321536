// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  googleWebClientId: '',
  firebase: {
    apiKey: "AIzaSyCq7eBnHhNb0SlA2_PqIhd-5aPHhBKcdnw",
    authDomain: "microjurisgateway.firebaseapp.com",
    databaseURL: "https://microjurisgateway.firebaseio.com",
    projectId: "microjurisgateway",
    storageBucket: "microjurisgateway.appspot.com",
    messagingSenderId: "618510604953",
    appId: "1:618510604953:web:fec2a2a79a047e130e3608",
    measurementId: '',
    vapidKey: ''
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
