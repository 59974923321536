<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content below * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * Delete the template below * * * * * * * * * * -->
<!-- * * * * * * * to get started with your project! * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->

<!-- <mat-toolbar color="primary">
  <span>Welcome to Microjuris Gateway - MJCLE</span>
  <span class="example-fill-remaining-space"></span>

  <span></span>
</mat-toolbar>

<div class="example-container">
  <mat-card>
  <mat-card-header>
    <mat-card-title>Login to get started:</mat-card-title>
  </mat-card-header>    
  <br>
  <mat-card-content>
  <form class="form" [formGroup]="validations_form" (ngSubmit)="tryLogin(validations_form.value)">
  <mat-form-field appearance="fill">
    <mat-label>Email</mat-label>
    <input matInput placeholder="user@example.com" formControlName="email" required>
    <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>
  </mat-form-field>
  <div class="validation-errors">
      <ng-container *ngFor="let validation of validation_messages.email">
        <div class="error-message"
             *ngIf="validations_form.get('email').hasError(validation.type) && (validations_form.get('email').dirty || validations_form.get('email').touched)">
          {{ validation.message }}
        </div>
      </ng-container>
    </div>
  <br>
  <mat-form-field appearance="fill">
    <mat-label>Password</mat-label>
    <input matInput [type]="hide ? 'password' : 'text'" formControlName="password">
    <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
    </button>
  </mat-form-field>
  <div class="validation-errors">
      <ng-container *ngFor="let validation of validation_messages.password">
        <div class="error-message"
             *ngIf="validations_form.get('password').hasError(validation.type) && (validations_form.get('password').dirty || validations_form.get('password').touched)">
          {{ validation.message }}
        </div>
      </ng-container>
    </div>
    <button mat-raised-button color="primary" type="submit" [disabled]="!validations_form.valid">Login</button>
    <button mat-raised-button color="primary">Recover Password</button>
  </form>  
  </mat-card-content>
  <br>
  <label class="error-message">{{errorMessage}}</label>
  </mat-card>
  <br>
</div>

<div class="footer">
  <p>© 2020 Microjuris Gateway - MJCLE</p>
</div> -->

<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content above * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * End of Placeholder * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->



<router-outlet></router-outlet>