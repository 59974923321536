import { Component, OnInit } from '@angular/core';
import { FirebaseService } from '../services/firebase.service';

@Component({
  selector: 'app-customers-edit-dialog',
  templateUrl: './customers-edit-dialog.component.html',
  styleUrls: ['./customers-edit-dialog.component.scss']
})
export class CustomersEditDialogComponent implements OnInit {

  constructor(
    public firebaseService: FirebaseService,
  ) { }

  ngOnInit(): void {
  }

}
