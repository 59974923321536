<h2 mat-dialog-title>Add New Customer</h2>
<mat-dialog-content class="mat-typography">
<div>
    <mat-form-field appearance="fill">
        <mat-label>First Name</mat-label>
        <input matInput name="firstName" [(ngModel)]="this.firebaseService.newCustomer.firstName">
    </mat-form-field>

    <mat-form-field appearance="fill" style="margin-left: 7px;">
        <mat-label>Initial</mat-label>
        <input matInput name="initial" [(ngModel)]="this.firebaseService.newCustomer.initial">
    </mat-form-field>
</div>    
<div>    
    <mat-form-field appearance="fill">
        <mat-label>Paternal Last Name</mat-label>
        <input matInput name="lastNameOne" [(ngModel)]="this.firebaseService.newCustomer.lastNameOne">
    </mat-form-field>
    <mat-form-field appearance="fill" style="margin-left: 7px;">
        <mat-label>Maternal Last Name</mat-label>
        <input matInput name="lastNameTwo" [(ngModel)]="this.firebaseService.newCustomer.lastNameTwo">
    </mat-form-field>    
</div>
<div>
    <mat-form-field appearance="fill">
        <mat-label>Email</mat-label>
        <input matInput name="email" [(ngModel)]="this.firebaseService.newCustomer.email">
    </mat-form-field>
    <mat-form-field appearance="fill" style="margin-left: 7px;">
        <mat-label>Password</mat-label>
        <input matInput name="password" [(ngModel)]="this.firebaseService.newCustomer.password">
    </mat-form-field>
</div>
<div>
    <mat-form-field appearance="fill">
        <mat-label>Mobile</mat-label>
        <input matInput name="mobile" [(ngModel)]="this.firebaseService.newCustomer.mobile">
    </mat-form-field>

    <mat-form-field appearance="fill" style="margin-left: 7px;">
        <mat-label>Is the customer a lawyer?</mat-label>
        <mat-select name="lawyerConf" [(ngModel)]="this.firebaseService.newCustomer.lawyerConf">
            <mat-option value="Si">Yes</mat-option>
            <mat-option value="No">No</mat-option>
        </mat-select>
    </mat-form-field>    
</div>
<div>    
    <mat-form-field appearance="fill">
        <mat-label>RUA</mat-label>
        <input matInput name="rua" [(ngModel)]="this.firebaseService.newCustomer.rua">
    </mat-form-field>
</div>
<!-- <button mat-button mat-dialog-close>Cancel</button>
<button mat-button [mat-dialog-close]="true" cdkFocusInitial>Save</button> -->
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial (click)="onSaveAddForm()">Save</button>
</mat-dialog-actions>
    <!-- <mat-form-field appearance="fill" style="margin-left: 7px;">
        <mat-label>Is the customer a lawyer?</mat-label>
        <input matInput>
    </mat-form-field> -->

        <!-- {{this.customer.selected | json}} -->