<form class="example-form">
    <mat-form-field class="example-full-width">
        <mat-label>Search</mat-label>
        <input matInput (keyup)="applyFilter($event.target.value)">
    </mat-form-field>
</form>

<div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="options">
                <th mat-header-cell *matHeaderCellDef> <button mat-icon-button color="primary" (click)="openNewDialog()"><mat-icon>add_box</mat-icon></button> </th>
                <td mat-cell *matCellDef="let course">
                    <!-- <button mat-icon-button color="primary">
                        <mat-icon>settings</mat-icon>
                    </button> -->
                    <button mat-icon-button [matMenuTriggerFor]="menu" color="primary">
                        <mat-icon>settings</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="openEditDialog(course)">
                            <mat-icon>edit</mat-icon>
                            <span>Edit</span>
                        </button>
                        <button mat-menu-item *ngIf="isCanDelete" (click)="onDelete(course.id)"> <!--disabled-->
                            <mat-icon>delete</mat-icon>
                            <span>Delete</span>
                        </button>
                        <!-- <button mat-menu-item>
                            <mat-icon>notifications_off</mat-icon>
                            <span>Disable alerts</span>
                        </button> -->
                    </mat-menu>                
            </ng-container>          

            <ng-container matColumnDef="courseRef">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Code </th>
                <td mat-cell *matCellDef="let course"> {{course.courseRef}} </td>
            </ng-container>            
      
          <ng-container matColumnDef="courseName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Course Name </th>
            <td mat-cell *matCellDef="let course"> {{course.courseName}} </td>
          </ng-container>

          <ng-container matColumnDef="courseCredit">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Course Credits </th>
            <td mat-cell *matCellDef="let course"> {{course.courseCredit}} </td>
        </ng-container>

          <ng-container matColumnDef="courseID">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Course ID </th>
            <td mat-cell *matCellDef="let course"> {{course.courseID}} </td>
          </ng-container>    
          
          <ng-container matColumnDef="activationType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Course Activation Type </th>
            <td mat-cell *matCellDef="let course" style="text-align: center;"> {{course.activationType}} </td>
          </ng-container>  
      
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      
        <mat-paginator [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons></mat-paginator>
      </div>
