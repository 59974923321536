import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {Sort} from '@angular/material/sort';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatSort} from '@angular/material/sort';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';
import { resolve } from 'url';
import {FirebaseService} from '../services/firebase.service';
import {Router} from '@angular/router';
import {FormControl, Validators, FormBuilder, FormGroup} from '@angular/forms';
import {CustomersI} from '../customers/customers';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import {AngularFireAuth} from '@angular/fire/auth';
import { Observable } from 'rxjs';
import {map} from 'rxjs/operators';
import * as firebase from 'firebase';
import 'firebase/firestore';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import { formatDate } from "@angular/common";

import * as moment from 'moment';

//let now = moment().format('YYYY-MM-DD');

export const MY_FORMATS = {
  parse: {
    dateInput: ['YYYY-MM-DDTHH:mm:ss'], //LL
  },
  display: {
    dateInput: 'YYYY-MM-DDTHH:mm:ss',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
  {position: 2, name: 'Helium', weight: 4.0026, symbol: 'He'},
  {position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li'},
  {position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be'},
  {position: 5, name: 'Boron', weight: 10.811, symbol: 'B'},
  {position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C'},
  {position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N'},
  {position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O'},
  {position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F'},
  {position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne'},
  {position: 11, name: 'Sodium', weight: 22.9897, symbol: 'Na'},
  {position: 12, name: 'Magnesium', weight: 24.305, symbol: 'Mg'},
  {position: 13, name: 'Aluminum', weight: 26.9815, symbol: 'Al'},
  {position: 14, name: 'Silicon', weight: 28.0855, symbol: 'Si'},
  {position: 15, name: 'Phosphorus', weight: 30.9738, symbol: 'P'},
  {position: 16, name: 'Sulfur', weight: 32.065, symbol: 'S'},
  {position: 17, name: 'Chlorine', weight: 35.453, symbol: 'Cl'},
  {position: 18, name: 'Argon', weight: 39.948, symbol: 'Ar'},
  {position: 19, name: 'Potassium', weight: 39.0983, symbol: 'K'},
  {position: 20, name: 'Calcium', weight: 40.078, symbol: 'Ca'},
];

//--------------------------------------------------------Main Customer View--------------------------------------------------------

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit {

  // displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  // dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);

  displayedColumns: string[] = ['options', 'firstName', 'initial', 'lastNameOne', 'lastNameTwo', 'email', 'rua', 'ruaisValid', 'credits', 'creditBalanceDate', 'actionOne', 'actionTwo'];
  //dataSource: MatTableDataSource<any>;
  dataSource = new MatTableDataSource();

  @ViewChild(MatSort) sort: MatSort;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  items: Array<any>;

  public isCanDelete: Boolean = false;

  public userData: any;

  constructor(
    private afs: AngularFirestore, 
    public dialog: MatDialog,
    public firebaseService: FirebaseService,
    private http: HttpClient,
    public authService: AuthService,
    public afAuth: AngularFireAuth,
    private router: Router,
  ) {
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.userData = user;
        //("this.userData", this.userData)
        this.afs.collection('userProfiles').doc(`${user.email}`).valueChanges().subscribe(data =>{
          //console.log('data: ', data)
          //console.log('data.deleteAdmin:', data["deleteAdmin"])
          this.isCanDelete = data["deleteAdmin"];
        });
      } else {
        console.log('Insufficient Permissions')
      }
    });
  }

  ngOnInit(): void { //: void 
    // this.dataSource.paginator = this.paginator;

    this.firebaseService.getAllCustomers().subscribe(res => {
      this.dataSource.data = res;
      this.dataSource.paginator = this.paginator;
    }); //console.log(res)

    // this.afs.collection<any>('userProfiles', ref => ref.where('profileType', '==', 'Customer')).valueChanges().subscribe(data => {
    //   this.dataSource = new MatTableDataSource(data); 
    //   this.dataSource.paginator = this.paginator;
    //   this.dataSource.sort = this.sort;
    // })
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    // this.afs.collection<any>('userProfiles', ref => ref.where('profileType', '==', 'Customer')).valueChanges().subscribe(data => {
    //   this.dataSource = new MatTableDataSource(data); 
    //   this.dataSource.paginator = this.paginator;
    //   this.dataSource.sort = this.sort;
    // })
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); 
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  loadData() {
    this.firebaseService.getAllCustomers().subscribe(res => {
      this.dataSource.data = res;
      this.dataSource.paginator = this.paginator;
    });
  }

  openEditDialog(customer) {
    const dialogRef = this.dialog.open(CustomerEditDialog);
    this.firebaseService.selected = customer;

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  openNewDialog() {
    const dialogRef = this.dialog.open(CustomerNewDialog);
    //this.firebaseService.selected = customer;

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  // onEdit(customer){
  //   this.firebaseService.selected = customer;
  // }

  onDelete(id:string){
    this.firebaseService.deleteCustomer(id);
  }

  openTransactionsDialog(customer) {
    const dialogRef = this.dialog.open(CustomerTransactions,{height:'auto',width:'100%'});
    this.firebaseService.selected = customer;

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      //window.location.reload();
    });
  }

  openCoursesDialog(customer) {
    const dialogRef = this.dialog.open(CustomerCourses,{height:'auto',width:'100%'});
    this.firebaseService.selected = customer;

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      //window.location.reload();
    });
  }

  // openDialog(data): void {
  //   const dialogRef = this.dialog.open(EditDialogComponent, {
  //     width: '350px',
  //     data: data
  //   });
  // }

}

//--------------------------------------------------------Edit Customer Dialog--------------------------------------------------------

@Component({
  selector: 'customer-edit-dialog',
  templateUrl: 'customer-edit-dialog.html',
})
export class CustomerEditDialog {

  savedValues: any;
  allValues: any;

  date = new FormControl(moment(['YYYY, MM, dd']));

  constructor(
    public firebaseService: FirebaseService,
    private afs: AngularFirestore, 
  ) { }

  onEdit(customer){
    this.firebaseService.selected = customer;
    //console.log('Edit', customer)
  }

  onSaveEditForm(){
    this.savedValues = this.firebaseService.selected;

    this.firebaseService.editCustomer(this.savedValues);
    //console.log('Save')

    //New Code Starts Here

    if(this.savedValues.customerRegistrationDate !== "" || this.savedValues.customerRegistrationDate !== null || this.savedValues.customerRegistrationDate != undefined){
      //change date format
      const format1 = 'yyyy-MM-ddTHH:mm:ss';
      const myDate1 = this.savedValues.customerRegistrationDate.toString();
      const locale1 = 'en-US';
      const formattedDate1 = formatDate(myDate1, format1, locale1);

      //console.log("formattedDate1: ", formattedDate1.toString())

      var dateSlice = formattedDate1.slice(8, -9);
      var currentDateSlice = dateSlice.toString();

      //console.log("currentDateSlice: ", currentDateSlice)

      var future = new Date(formattedDate1);
      var inThirtyDays = future.setDate(future.getDate() + 30);

      //console.log("inThirtyDays: ",inThirtyDays)

      const format2 = 'yyyy-MM-ddTHH:mm:ss';
      const myDate2 = inThirtyDays.toString();
      const locale2 = 'en-US';
      const formattedDate2 = formatDate(myDate2, format2, locale2);

      //console.log("formattedDate2: ", formattedDate2)

      var newDateFormats = {
        customerRegistrationDate: formattedDate1,
        customerRegistrationDay: dateSlice.toString(),
        nextIncrDate: formattedDate2.toString(),
      }

      this.allValues = newDateFormats;

      this.afs.collection('userProfiles').doc(`${this.firebaseService.selected.email}`).update(newDateFormats);

    }

    //New Code Ends Here

  }

}

//--------------------------------------------------------New Customer Dialog (Not Being Used)---------------------------------------
@Component({
  selector: 'customer-new-dialog',
  templateUrl: 'customer-new-dialog.html',
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class CustomerNewDialog {

  savedValues: any;
  newValues: any;

  constructor(
    public firebaseService: FirebaseService,
    public dialog: MatDialog,
  ) { }

  //onEdit(customer){
    //this.firebaseService.selected = customer;
    //console.log('Edit', customer)
  //}

  onAddNew(customer){
    
  }

  //--------------------------------------------------------Open Customer Added Confirmation------------------------------------------

  openAddConfirmDialog() {
    const dialogRef = this.dialog.open(CustomerAddConfirmDialog);
    //this.firebaseService.selected = customer;

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      this.firebaseService.newCustomer.firstName = "";
      this.firebaseService.newCustomer.initial = "";
      this.firebaseService.newCustomer.lastNameOne = "";
      this.firebaseService.newCustomer.lastNameTwo = "";
      this.firebaseService.newCustomer.email = "";
      this.firebaseService.newCustomer.password = "";
      this.firebaseService.newCustomer.mobile = "";
      this.firebaseService.newCustomer.lawyerConf = "";
      this.firebaseService.newCustomer.rua = "";
      //window.location.reload();
    });
  }

  //--------------------------------------------------------Save New Customer--------------------------------------------------------

  onSaveAddForm(){
    //this.savedValues = this.firebaseService.selected;
    //if(this.firebaseService.newCustomer.id == null){

      //get current date
      var now = moment().format('YYYY-MM-DDTHH:mm:ss');

      var currentDate = now.toString();

      var dateSlice = currentDate.slice(8, -9);

      //console.log("dateSlice: ", dateSlice)

      //get next 30 days
      var inThirtyDays = moment().add(30, 'days').format('YYYY-MM-DDTHH:mm:ss');
      
      //new customer
      let newCustomerData = {
        firstName: this.firebaseService.newCustomer.firstName.trim(),
        initial: this.firebaseService.newCustomer.initial.trim(),
        lastNameOne: this.firebaseService.newCustomer.lastNameOne.trim(),
        lastNameTwo: this.firebaseService.newCustomer.lastNameTwo.trim(),
        email: this.firebaseService.newCustomer.email.toLowerCase().trim(),
        password: this.firebaseService.newCustomer.password.trim(),
        mobile: this.firebaseService.newCustomer.mobile.trim(),
        lawyerConf: this.firebaseService.newCustomer.lawyerConf,
        rua: this.firebaseService.newCustomer.rua.trim(),
        displayName:this.firebaseService.newCustomer.email.toLowerCase().trim(),
        superAdmin:false,
        ruaisValid:false,
        Admin:false,
        deleteAdmin:false,
        role:"User",
        profileType:"Customer",
        uid:this.firebaseService.newCustomer.email.toLowerCase().trim(),
        credits:'0',
        creditBalanceDate:'',
        creditIncr: '0',
        customerRegistrationDate: currentDate,
        productType: 'N',
        customerRegistrationDay: dateSlice.toString(),
        nextIncrDate: inThirtyDays.toString(),
      }
      //console.log('New', newCustomerData)
      this.newValues = newCustomerData;
      this.firebaseService.addCustomer(this.newValues).then(async()=>{
        //await window.location.reload();
        await this.openAddConfirmDialog();
      });
    //}
    // else{
    //   this.firebaseService.editCustomer(this.savedValues);
    //   console.log('Save')
    // }
  }

}

//--------------------------------------------------------Add Customer Confirmation Dialog------------------------------------

@Component({
  selector: 'customer-add-confirm-dialog',
  templateUrl: 'customer-add-confirm-dialog.html',
})
export class CustomerAddConfirmDialog {

  constructor(
    public firebaseService: FirebaseService,
  ) { }


}  

//--------------------------------------------------------Transactions--------------------------------------------------------

@Component({
  selector: 'customer-transactions',
  templateUrl: 'customer-transactions.html',
})
export class CustomerTransactions implements OnInit {

  displayedColumns: string[] = ['id', 'creditBalanceDate', 'credits', 'netTotal', 'ivuAmount', 'total'];
  //dataSource: MatTableDataSource<any>;
  dataSource = new MatTableDataSource();

  @ViewChild(MatSort) sort: MatSort;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  items: Array<any>;

  customerCredits: any;

  constructor(
    private afs: AngularFirestore, 
    public dialog: MatDialog,
    public firebaseService: FirebaseService,
  ) { }

  ngOnInit(): void { //: void 

    this.afs.collection<any>('creditsFormstack', ref => ref.where('uid_FK', '==', this.firebaseService.selected.email)).valueChanges().subscribe(data => {
      this.dataSource = new MatTableDataSource(data); 
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })

    this.subscribeCustomer();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  subscribeCustomer(){
    // this.afs.collection<any>('userProfiles', ref => ref.where('uid', '==', this.firebaseService.selected.email)).valueChanges().subscribe(data => {
    //   this.dataSource = new MatTableDataSource(data); 
    //   this.dataSource.paginator = this.paginator;
    //   this.dataSource.sort = this.sort;
    //   console.log("Subscribe Customer Credits:", data["credits"])
    // })

    this.afs.collection('userProfiles').doc(this.firebaseService.selected.email).valueChanges().subscribe(data =>{
      //console.log("Subscribe Customer Credits:", data["credits"])
      this.customerCredits = data["credits"];
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); 
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  openAddCreditsDialog() {
    const dialogRef = this.dialog.open(CustomerAddCreditsDialog);
    //this.firebaseService.selected = customer;

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      this.subscribeCustomer();
    });
  }

}


//--------------------------------------------------------Courses--------------------------------------------------------

@Component({
  selector: 'customer-courses',
  templateUrl: 'customer-courses.html',
})
export class CustomerCourses implements OnInit {

  displayedColumns: string[] = ['id', 'courseName', 'credits', 'eject'];
  //dataSource: MatTableDataSource<any>;
  dataSource = new MatTableDataSource();

  @ViewChild(MatSort) sort: MatSort;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  items: Array<any>;

  customerCredits: any;

  constructor(
    private afs: AngularFirestore, 
    public dialog: MatDialog,
    public firebaseService: FirebaseService,
    private http: HttpClient,
  ) { }

  ngOnInit(): void { //: void 

    this.afs.collection<any>('coursesFormstack', ref => ref.where('email', '==', this.firebaseService.selected.email).where('registrationStatus', '==', 'Activation Completed')).valueChanges().subscribe(data => {
      this.dataSource = new MatTableDataSource(data); 
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); 
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  openCourseHistoryDialog() {
    const dialogRef = this.dialog.open(CustomerCoursesHistory,{height:'auto',width:'100%'});
    //this.firebaseService.selected = course;
    this.firebaseService.selected.digitalchalkID;
    //console.log("DC ID:", this.firebaseService.selected.digitalchalkID)

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      //window.location.reload();
    });
  }

  openEjectConfirmDialog() {
    const dialogRef = this.dialog.open(EjectConfirmDialog);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      window.location.reload();
    });
  }

  ejectCourse(transaction:any){
    this.firebaseService.course = transaction;
    this.afs.collection<any>('coursesFormstack', ref => ref.where('email', '==', this.firebaseService.selected.email)).valueChanges().subscribe(data => {

      //console.log("registrationID: ", this.firebaseService.course.registrationID)

      let url = `https://api.digitalchalk.com/dc/api/v5/registrations/${this.firebaseService.course.registrationID}`;

      const headers = new HttpHeaders().set('Host', 'api.digitalchalk.com').set('Content-Type', 'application/json').set('Authorization', 'Bearer 3f970204-f2e9-4c45-add4-a24e9911816b');

      let body = {
        status: "ejected"
      }

      return this.http.put(url,body,{headers})
                    .toPromise()
                        .then( res => {
                          //console.log(res)
                          this.openEjectConfirmDialog();
                        },
                        err => {
                          console.log("Error occured");
                        });
    })

  }

}

//--------------------------------------------------------Add Credits--------------------------------------------------------

@Component({
  selector: 'customer-add-credits-dialog',
  templateUrl: 'customer-add-credits-dialog.html',
})
export class CustomerAddCreditsDialog {

  newCreditsValues: any;
  currentDocID: any = {};
  updateCreditValues: any;
  updateUserProfilesValues: any;

  constructor(
    public firebaseService: FirebaseService,
    public dialog: MatDialog,
    private afs: AngularFirestore, 
    private http: HttpClient,
    private router: Router,
  ) { }

  dataSource = new MatTableDataSource();

  @ViewChild(MatSort) sort: MatSort;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  items: Array<any>;

  openAddConfirmDialog() {
    const dialogRef = this.dialog.open(CustomerAddCreditsConfirmDialog);
    //this.firebaseService.selected = customer;

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      this.firebaseService.newCredit.credits = "";
      this.firebaseService.newCredit.costPerCredit = "";
      //window.location.reload();
      //location.reload();
      this.loadData2();
    });
  }

  loadData() {
    this.firebaseService.getAllCustomers().subscribe(res => {
      this.dataSource.data = res;
      this.dataSource.paginator = this.paginator;
    });
  }

  loadData2() {
    this.firebaseService.getAllCustomers().subscribe(res => {
      this.dataSource.data = res;
      this.dataSource.paginator = this.paginator;
    });
  }

  addManualCredits(){

    //("Credits: ", this.firebaseService.newCredit.credits)
    //console.log("Cost Per Credit: ", this.firebaseService.newCredit.costPerCredit)

    //----------- Variables -----------

    var costPerCredit;
    var newBalance;
    var newBalance1;
    var newBalance2;
    var newBalance3;
    var newBalance4;
    var newBalance5;
    var differenceCredits;
    var cost1;
    var cost2;
    var sumCosts;
    var costPerCredit2;
    var differenceCredits2;
    var cost3;
    var cost4;
    var cost5;
    var differenceCredits3;
    var differenceCredits4;
    var costPerCredit3;
    var costPerCredit4;
    var costPerCredit5;

    //----------- Get Current Date -----------

    var defaultTotal = "0.00";
    var date = new Date();
    const timestamp = date.valueOf();
    date = new Date(timestamp);


    // Unixtimestamp
    var unixtimestamp = Date.now();

    // Months array
    var months_arr = ['01','02','03','04','05','06','07','08','09','10','11','12'];
    //var months_arr = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];

    // Days array
    var days_arr = ['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31'];

    // Convert
    var currentDate = new Date(unixtimestamp);

    // Year
    var year = currentDate.getFullYear();

    // Month
    var month = months_arr[currentDate.getMonth()]; 

    // Day
    var day = days_arr[currentDate.getDate()];
    
    // Hours
    var hours = currentDate.getHours();

    // Minutes
    var minutes = "0" + currentDate.getMinutes();

    // Seconds
    var seconds = "0" + currentDate.getSeconds();

    // Display date time in MM-dd-yyyy h:m:s format
    var formatDateTime = year+'-'+month+'-'+day+' '+hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);

    var dateTime = formatDateTime;

    //console.log('Current Date: ', dateTime);

    //----------- Assign Values to Variables from Selected Customer -----------

    var defaultTotalPrice = "0.00";
    var dateTime = dateTime.toString();
    var memo = "Initial Balance";
    var typeOfTransaction = "Manual";

    var creditsToAdd: any;
    var fifoCostToAdd: any;

    creditsToAdd = this.firebaseService.newCredit.credits;
    fifoCostToAdd = this.firebaseService.newCredit.costPerCredit;

    var newCreditsData = {
      firstName: this.firebaseService.selected.firstName,
      initial: this.firebaseService.selected.initial,
      lastNameOne: this.firebaseService.selected.lastNameOne,
      lastNameTwo: this.firebaseService.selected.lastNameTwo,
      email: this.firebaseService.selected.email.toLowerCase().trim(),
      rua: this.firebaseService.selected.rua,
      mobile: this.firebaseService.selected.mobile,
      netTotal: '0.00',
      credits: parseFloat(creditsToAdd).toFixed(2),
      FIFOCostPerCredit: parseFloat(fifoCostToAdd).toFixed(2),
      creditBalanceDate: dateTime,
      memo: memo,
      typeOfTransaction: typeOfTransaction,
      uid_FK: this.firebaseService.selected.email.toLowerCase().trim(),
      lawyerConf: this.firebaseService.selected.lawyerConf,
      total: "-",
      ivuAmount: "-",
      creditsFIFOBalance: parseFloat(this.firebaseService.newCredit.credits),
      status: "In Process"
    };

    //console.log('New Manual Credits', newCreditsData)

    this.newCreditsValues = newCreditsData;

    //console.log("this.newCreditsValues: ", this.newCreditsValues)

    this.firebaseService.addCredit(this.newCreditsValues).then(async(docData)=>{

      //console.log('docData: ', docData.id)

      this.currentDocID = docData.id;
      //console.log('Document ID: ', this.currentDocID)

      var netTotal;
      netTotal = parseFloat(newCreditsData.credits) * parseFloat(newCreditsData.FIFOCostPerCredit);
              
      var totalPrice = netTotal + (netTotal * 0.04);

      var ivuAmount: any; 
      ivuAmount = (netTotal * 0.04);
      
      const docID = {
        id: docData.id,
        total: parseFloat(totalPrice).toFixed(2),
        netTotal: parseFloat(netTotal).toFixed(2),
        ivuAmount: parseFloat(ivuAmount).toFixed(2)
      }

      //console.log('id: ', docID.id)
      //console.log('total: ', docID.total)
      //console.log('netTotal: ', docID.netTotal)
      //console.log('ivuAmount: ', docID.ivuAmount)

      this.updateCreditValues = docID;

      //console.log("this.updateCreditValues: ", this.updateCreditValues)

      //-----------------------------NEW CODE STARTS HERE-----------------------------

      let promise = new Promise((resolve, reject) => {

        var addCredits = this.firebaseService.editCredits(this.updateCreditValues).then(()=>{

          if((docData.id !== null || docData.id !== undefined || docData.id !== "")){
  
            this.openAddConfirmDialog();
            this.loadData();
  
          }
  
        });

        resolve(addCredits);
      });
      
      let result = await promise; // wait until the promise resolves (*)
      
      console.log(result); // "done!" 

      //-----------------------------NEW CODE ENDS HERE-----------------------------

      //-----------------------------ORIGINAL CODE STARTS HERE-----------------------------

      // this.firebaseService.editCredits(this.updateCreditValues).then(()=>{

      //   if((docData.id !== null || docData.id !== undefined || docData.id !== "")){

      //     this.openAddConfirmDialog();
      //     this.loadData();

      //     // var userDocId = this.firebaseService.selected.email;
    
      //     // console.log("userDocId: ", userDocId)

      //     // var docIdUser = userDocId;

      //     // console.log("docIdUser: ", docIdUser)

      //     // var creditTotal;
      //     // var creditsInputOne = this.firebaseService.newCredit.credits;
      //     // var creditsInputTwo = this.firebaseService.newCredit.credits;
      //     // var currentCreditBalance = this.firebaseService.selected.credits;
      //     // creditTotal = parseFloat(currentCreditBalance) + (parseFloat(creditsInputOne)-parseFloat(creditsInputTwo));
  
      //     // var creditsData = {
      //     //   credits: parseFloat(creditTotal).toFixed(2),
      //     //   creditBalanceDate: newCreditsData.creditBalanceDate
      //     // }
  
      //     // console.log('Credits: ', creditsData.credits);

      //     // console.log('User Profiles Doc Id 3: ', docIdUser)

      //     // this.updateUserProfilesValues = creditsData;

      //     // console.log("this.updateUserProfilesValues: ", this.updateUserProfilesValues)

      //     //this.firebaseService.editUsers(this.updateUserProfilesValues);
      //   }

      // });

      //-----------------------------ORIGINAL CODE ENDS HERE-----------------------------

    });

  }


} 

//--------------------------------------------------------Digital Chalk Courses History--------------------------------------------------------

@Component({
  selector: 'customer-course-history-dialog',
  templateUrl: 'customer-course-history-dialog.html',
})
export class CustomerCoursesHistory implements OnInit {

  displayedColumns: string[] = ['courseTitle', 'beginDate', 'endDate', 'registrationStatus', 'courseGrade'];
  //dataSource: MatTableDataSource<any>;
  dataSource = new MatTableDataSource();

  @ViewChild(MatSort) sort: MatSort;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  items: Array<any>;

  customerCredits: any;

  constructor(
    private afs: AngularFirestore, 
    public dialog: MatDialog,
    public firebaseService: FirebaseService,
  ) { }

  ngOnInit(): void { //: void 

    this.afs.collection<any>('digitalChalkRegistrations', ref => ref.where('digitalChalkUserID', '==', this.firebaseService.selected.digitalchalkID)).valueChanges().subscribe(data => {
      this.dataSource = new MatTableDataSource(data); 
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); 
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

}

//--------------------------------------------------------Add Credits Confirmation Dialog------------------------------------

@Component({
  selector: 'customer-add-credits-confirm',
  templateUrl: 'customer-add-credits-confirm.html',
})
export class CustomerAddCreditsConfirmDialog {

  constructor(
    public firebaseService: FirebaseService,
  ) { }


} 

//--------------------------------------------------------Eject Confirmation Dialog------------------------------------

@Component({
  selector: 'customer-eject-confirm',
  templateUrl: 'customer-eject-confirm.html',
})
export class EjectConfirmDialog {

  constructor(
    public firebaseService: FirebaseService,
  ) { }


} 