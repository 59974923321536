<h2 mat-dialog-title>Edit Customer</h2>
<mat-dialog-content class="mat-typography">
<div>
    <mat-form-field appearance="fill">
        <mat-label>First Name</mat-label>
        <input matInput name="firstName" [(ngModel)]="this.firebaseService.selected.firstName">
    </mat-form-field>

    <mat-form-field appearance="fill" style="margin-left: 7px;">
        <mat-label>Initial</mat-label>
        <input matInput name="initial" [(ngModel)]="this.firebaseService.selected.initial">
    </mat-form-field>
</div>    
<div>    
    <mat-form-field appearance="fill">
        <mat-label>Paternal Last Name</mat-label>
        <input matInput name="lastNameOne" [(ngModel)]="this.firebaseService.selected.lastNameOne">
    </mat-form-field>
    <mat-form-field appearance="fill" style="margin-left: 7px;">
        <mat-label>Maternal Last Name</mat-label>
        <input matInput name="lastNameTwo" [(ngModel)]="this.firebaseService.selected.lastNameTwo">
    </mat-form-field>    
</div>
<div>
    <mat-form-field appearance="fill">
        <mat-label>Email</mat-label>
        <input matInput name="email" [(ngModel)]="this.firebaseService.selected.email">
    </mat-form-field>
    <mat-form-field appearance="fill" style="margin-left: 7px;">
        <mat-label>Password</mat-label>
        <input matInput name="password" [(ngModel)]="this.firebaseService.selected.password">
    </mat-form-field>
</div>
<div>
    <mat-form-field appearance="fill">
        <mat-label>Mobile</mat-label>
        <input matInput name="mobile" [(ngModel)]="this.firebaseService.selected.mobile">
    </mat-form-field>

    <mat-form-field appearance="fill" style="margin-left: 7px;">
        <mat-label>Is the customer a lawyer?</mat-label>
        <mat-select name="lawyerConf" [(ngModel)]="this.firebaseService.selected.lawyerConf">
            <mat-option value="Si">Yes</mat-option>
            <mat-option value="No">No</mat-option>
        </mat-select>
    </mat-form-field>    
</div>
<div>    
    <mat-form-field appearance="fill">
        <mat-label>RUA</mat-label>
        <input matInput name="rua" [(ngModel)]="this.firebaseService.selected.rua">
    </mat-form-field>
    <mat-form-field appearance="fill" style="margin-left: 7px;">
        <mat-label>Product Type</mat-label>
        <mat-select name="productType" [(ngModel)]="this.firebaseService.selected.productType">
            <mat-option value="N">Normal</mat-option>
            <mat-option value="B">Bundle</mat-option>
        </mat-select>
    </mat-form-field>  
</div>
<div>
    <mat-form-field appearance="fill">
        <mat-label>Credit Increment</mat-label>
        <input matInput name="creditIncr" [(ngModel)]="this.firebaseService.selected.creditIncr">
    </mat-form-field>
    <mat-form-field style="margin-left: 7px;">
        <mat-label>Registration Date</mat-label>
        <input matInput [matDatepicker]="pickerOne" [(ngModel)]='this.firebaseService.selected.customerRegistrationDate' name="customerRegistrationDate" [formControl]="date"> <!--required-->
        <mat-datepicker-toggle matSuffix [for]="pickerOne"></mat-datepicker-toggle>
        <mat-datepicker #pickerOne></mat-datepicker>
    </mat-form-field>
    <!-- <mat-form-field appearance="fill" style="margin-left: 7px;">
        <mat-label>Registration Date</mat-label>
        <input matInput name="customerRegistrationDate" [(ngModel)]="this.firebaseService.selected.customerRegistrationDate">
    </mat-form-field> -->
</div>
<!-- <button mat-button mat-dialog-close>Cancel</button>
<button mat-button [mat-dialog-close]="true" cdkFocusInitial>Save</button> -->
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial (click)="onSaveEditForm()">Save</button>
</mat-dialog-actions>
    <!-- <mat-form-field appearance="fill" style="margin-left: 7px;">
        <mat-label>Is the customer a lawyer?</mat-label>
        <input matInput>
    </mat-form-field> -->

        <!-- {{this.customer.selected | json}} -->