<mat-toolbar color="primary">
    <span>Digital Chalk Courses History</span>
</mat-toolbar>
<mat-dialog-content style="width: 100%;">
<div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" style="width: 100%;" matSort>
  
      <!-- Transaction ID Column -->
      <ng-container matColumnDef="courseTitle">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Course Name </th>
        <td mat-cell *matCellDef="let course"> {{course.courseTitle}} </td>
      </ng-container>
  
      <!-- Transaction Date Column -->
      <ng-container matColumnDef="beginDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Begin Date </th>
        <td mat-cell *matCellDef="let course"> {{course.beginDate}} </td>
      </ng-container>
  
      <!-- Credits Column -->
      <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> End Date </th>
        <td mat-cell *matCellDef="let course"> {{course.endDate}} </td>
      </ng-container>
  
      <!-- Net Total Column -->
      <ng-container matColumnDef="registrationStatus">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Registration Status </th>
        <td mat-cell *matCellDef="let course"> {{course.registrationStatus}} </td>
      </ng-container>

        <!-- IVU Amount Column -->
        <ng-container matColumnDef="courseGrade">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Course Grade </th>
            <td mat-cell *matCellDef="let course"> {{course.courseGrade}} </td>
        </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>OK</button>
</mat-dialog-actions>