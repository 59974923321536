<!-- <mat-toolbar color="primary">
    <mat-toolbar-row>
        <span>Transactions</span>
    </mat-toolbar-row>
</mat-toolbar> -->
<mat-toolbar color="primary">
        <span>Courses</span>
        <span style="flex: 1 1 auto;"></span>
              
        <span>
            <button mat-icon-button (click)="openCourseHistoryDialog()">
                <mat-icon>history</mat-icon>
            </button>
        </span>
    </mat-toolbar>
    <mat-dialog-content style="width: 100%;">
    <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" style="width: 100%;" matSort>
      
          <!-- Transaction ID Column -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Transaction ID </th>
            <td mat-cell *matCellDef="let transaction"> {{transaction.id}} </td>
          </ng-container>
      
          <!-- Transaction Date Column -->
          <ng-container matColumnDef="courseName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Course Name </th>
            <td mat-cell *matCellDef="let transaction"> {{transaction.courseName}} </td>
          </ng-container>
      
          <!-- Credits Column -->
          <ng-container matColumnDef="credits"> 
            <th mat-header-cell *matHeaderCellDef mat-sort-header>  Course Credits </th>
            <td mat-cell *matCellDef="let transaction"> {{transaction.credits}} </td>
          </ng-container>
      
          <!-- Net Total Column -->
          <ng-container matColumnDef="eject">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Eject (without Credits) </th>
            <td mat-cell *matCellDef="let transaction">  
                <button mat-stroked-button color="primary" (click)="ejectCourse(transaction)">
                    <mat-icon>eject</mat-icon><!--&nbsp;Eject--> 
                </button>
            </td>
          </ng-container>

      
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>Cancel</button>
        <button mat-button [mat-dialog-close]="true" cdkFocusInitial>OK</button>
    </mat-dialog-actions>