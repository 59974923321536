import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {Sort} from '@angular/material/sort';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatSort} from '@angular/material/sort';
import {AngularFirestore} from '@angular/fire/firestore';
import {CourseRequestsI} from '../courserequests/courerequests';
import {FirebaseService} from '../services/firebase.service';
import {AngularFireAuth} from '@angular/fire/auth';

@Component({
  selector: 'app-courserequests',
  templateUrl: './courserequests.component.html',
  styleUrls: ['./courserequests.component.scss']
})
export class CourserequestsComponent implements OnInit {

  displayedColumns: string[] = ['options', 'id', 'firstName', 'initial', 'lastNameOne', 'lastNameTwo', 'email', 'mobile', 'rua', 'credits', 'courseRef', 'courseName', 'status', 'registrationStatus', 'statusMessage', 'emailStatus'];
  dataSource = new MatTableDataSource();

  @ViewChild(MatSort) sort: MatSort;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  public isCanDelete: Boolean = false;

  public userData: any;

  constructor(
    private afs: AngularFirestore, 
    public dialog: MatDialog,
    public firebaseService: FirebaseService,
    public afAuth: AngularFireAuth,
  ) { 
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.userData = user;
        //console.log("this.userData", this.userData)
        this.afs.collection('userProfiles').doc(`${user.email}`).valueChanges().subscribe(data =>{
          //console.log('data: ', data)
          //console.log('data.deleteAdmin:', data["deleteAdmin"])
          this.isCanDelete = data["deleteAdmin"];
        });
      } else {
        console.log('Insufficient Permissions')
      }
    });
  }

  ngOnInit(): void {
    this.firebaseService.getAllCourseRequests().subscribe(res => {
      this.dataSource.data = res;
      this.dataSource.paginator = this.paginator;
    });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    // this.afs.collection<any>('coursesFormstack').valueChanges().subscribe(data => {
    //   this.dataSource = new MatTableDataSource(data); 
    //   this.dataSource.paginator = this.paginator;
    //   this.dataSource.sort = this.sort;
    // })
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); 
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  openEditDialog(course) {
    const dialogRef = this.dialog.open(CourserequestEditDialog);
    this.firebaseService.selectedCourseRequest = course;

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  openNewDialog() {
    const dialogRef = this.dialog.open(CourserequestNewDialog);
    //this.firebaseService.selected = customer;

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  onDelete(id:string){
    this.firebaseService.deleteCourseRequests(id);
  }

}

//--------------------------------------------------------Edit Course Request Dialog--------------------------------------------------------

@Component({
  selector: 'courserequest-edit-dialog',
  templateUrl: 'courserequest-edit-dialog.html',
})
export class CourserequestEditDialog {

  savedValues: any;

  constructor(
    public firebaseService: FirebaseService,
  ) { }

  onEdit(course){
    this.firebaseService.selectedCourseRequest = course;
    //console.log('Edit', customer)
  }

  onSaveEditForm(){
    this.savedValues = this.firebaseService.selectedCourseRequest;
    this.firebaseService.editCourseRequests(this.savedValues);
    //console.log('Save')
  }

}

//--------------------------------------------------------Add New Course Request Dialog--------------------------------------------------------

@Component({
  selector: 'add-new-course-request',
  templateUrl: 'add-new-course-request.html',
})
export class CourserequestNewDialog {

  savedValues: any;
  newValues: any;

  constructor(
    public firebaseService: FirebaseService,
    public dialog: MatDialog,
  ) { }

  onSaveAddForm(){
    //this.savedValues = this.firebaseService.selected;
    //if(this.firebaseService.newCustomer.id == null){
      //new customer
      let newCourseReqData = {
        id: this.firebaseService.newCourseRequest.id,
        firstName: this.firebaseService.newCourseRequest.firstName,
        initial: this.firebaseService.newCourseRequest.initial,
        lastNameOne: this.firebaseService.newCourseRequest.lastNameOne,
        lastNameTwo: this.firebaseService.newCourseRequest.lastNameTwo,
        email: this.firebaseService.newCourseRequest.email,
        mobile: this.firebaseService.newCourseRequest.mobile,
        rua: this.firebaseService.newCourseRequest.rua,
        credits: this.firebaseService.newCourseRequest.credits,
        courseRef: this.firebaseService.newCourseRequest.courseRef,
        courseName: this.firebaseService.newCourseRequest.firstName,
        courseID: this.firebaseService.newCourseRequest.courseID,
        FIFOCostPerCredit: this.firebaseService.newCourseRequest.FIFOCostPerCredit,
        CostPerCreditFIFO: '',
        FIFOCostCalculated: '',
        digitalchalkEmail: this.firebaseService.newCourseRequest.email,
        digitalchalkID: '',
        fifoStatus: '',
        professorName: '',
        purchaseType: '',
        queueCounter: 0,
        registrationDate: '',
        registrationID: '',
        registrationStatus: '',
        status: '',
        statusMessage: '',
        title: ''
      }
      //('New', newCourseReqData)
      this.newValues = newCourseReqData;
      this.firebaseService.addCourseRequests(this.newValues).then(async()=>{
        //await window.location.reload();
        await this.openAddConfirmDialog();
      });
    //}
    // else{
    //   this.firebaseService.editCustomer(this.savedValues);
    //   console.log('Save')
    // }
  }

  openAddConfirmDialog() {
    const dialogRef = this.dialog.open(CourseRequestAddConfirmDialog);
    //this.firebaseService.selected = customer;

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      this.firebaseService.newCourseRequest.id = "";
      this.firebaseService.newCourseRequest.firstName = "";
      this.firebaseService.newCourseRequest.initial = "";
      this.firebaseService.newCourseRequest.lastNameOne = "";
      this.firebaseService.newCourseRequest.lastNameTwo = "";
      this.firebaseService.newCourseRequest.email = "";
      this.firebaseService.newCourseRequest.mobile = "";
      this.firebaseService.newCourseRequest.rua = "";
      this.firebaseService.newCourseRequest.credits = "";
      this.firebaseService.newCourseRequest.courseRef = "";
      this.firebaseService.newCourseRequest.courseName = "";
      this.firebaseService.newCourseRequest.courseID = "";
      this.firebaseService.newCourseRequest.FIFOCostPerCredit = "";
      //window.location.reload();
    });
  }

}

//--------------------------------------------------------Add Course Request Confirmation Dialog------------------------------------

@Component({
  selector: 'coursereq-add-confirm-dialog',
  templateUrl: 'coursereq-add-confirm-dialog.html',
})
export class CourseRequestAddConfirmDialog {

  constructor(
    public firebaseService: FirebaseService,
  ) { }


} 
