import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {Sort} from '@angular/material/sort';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatSort} from '@angular/material/sort';
import {AngularFirestore} from '@angular/fire/firestore';
import {CoursesI} from '../courses/courses';
import {FirebaseService} from '../services/firebase.service';
import {AngularFireAuth} from '@angular/fire/auth';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss']
})
export class CoursesComponent implements OnInit {

  displayedColumns: string[] = ['options', 'courseRef', 'courseName', 'courseCredit', 'courseID', 'activationType'];
  dataSource = new MatTableDataSource();

  @ViewChild(MatSort) sort: MatSort;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  public isCanDelete: Boolean = false;

  public userData: any;

  constructor(
    private afs: AngularFirestore, 
    public dialog: MatDialog,
    public firebaseService: FirebaseService,
    public afAuth: AngularFireAuth,
  ) { 
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.userData = user;
        //console.log("this.userData", this.userData)
        this.afs.collection('userProfiles').doc(`${user.email}`).valueChanges().subscribe(data =>{
          //console.log('data: ', data)
          //console.log('data.deleteAdmin:', data["deleteAdmin"])
          this.isCanDelete = data["deleteAdmin"];
        });
      } else {
        console.log('Insufficient Permissions')
      }
    });
  }

  ngOnInit(): void {
    this.firebaseService.getAllCourses().subscribe(res => {
      this.dataSource.data = res;
      this.dataSource.paginator = this.paginator;
    });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    // this.afs.collection<any>('masterListCourses').valueChanges().subscribe(data => {
    //   this.dataSource = new MatTableDataSource(data); 
    //   this.dataSource.paginator = this.paginator;
    //   this.dataSource.sort = this.sort;
    // })
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); 
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  openEditDialog(course) {
    const dialogRef = this.dialog.open(CoursesEditDialog);
    this.firebaseService.selectedCourse = course;

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  openNewDialog() {
    const dialogRef = this.dialog.open(CourseNewDialog);
    //this.firebaseService.selected = customer;

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  onDelete(id:string){
    this.firebaseService.deleteCourses(id);
  }

}

//--------------------------------------------------------Edit Courses Dialog--------------------------------------------------------

@Component({
  selector: 'courses-edit-dialog',
  templateUrl: 'courses-edit-dialog.html',
})
export class CoursesEditDialog {

  savedValues: any;

  constructor(
    public firebaseService: FirebaseService,
  ) { }

  onEdit(course){
    this.firebaseService.selectedCourse = course;
    //console.log('Edit', customer)
  }

  onSaveEditForm(){
    this.savedValues = this.firebaseService.selectedCourse;
    this.firebaseService.editCourses(this.savedValues);
    //console.log('Save')
  }
}

//--------------------------------------------------------Add New Course Dialog--------------------------------------------------------

@Component({
  selector: 'courses-add-new-dialog',
  templateUrl: 'courses-add-new-dialog.html',
})
export class CourseNewDialog {

  savedValues: any;
  newValues: any;

  constructor(
    public firebaseService: FirebaseService,
    public dialog: MatDialog,
  ) {}

  onSaveAddForm(){
    //this.savedValues = this.firebaseService.selected;
    //if(this.firebaseService.newCustomer.id == null){
      //new customer
      let newCourseData = {
        courseCredit: this.firebaseService.newCourse.courseCredit.trim(),
        courseID: this.firebaseService.newCourse.courseID.trim(),
        courseName: this.firebaseService.newCourse.courseName.trim(),
        courseRef: this.firebaseService.newCourse.courseRef.trim(),
        courseStatus: this.firebaseService.newCourse.courseStatus.trim(),
        endDate: this.firebaseService.newCourse.endDate.trim(),
        horasEtica: this.firebaseService.newCourse.horasEtica.trim(),
        horasGeneral: this.firebaseService.newCourse.horasGeneral.trim(),
        horasNotaria: this.firebaseService.newCourse.horasNotaria.trim(),
        professorName: this.firebaseService.newCourse.professorName.trim(),
        startDate: this.firebaseService.newCourse.startDate.trim(),
        title: '',
        topic: this.firebaseService.newCourse.topic.trim(),
        id: this.firebaseService.newCourse.courseID.trim(),
        activationType: this.firebaseService.newCourse.activationType.trim()
      }
      //console.log('New', newCourseData)
      this.newValues = newCourseData;
      this.firebaseService.addCourses(this.newValues).then(async()=>{
        //await window.location.reload();
        await this.openAddConfirmDialog();
      });
    //}
    // else{
    //   this.firebaseService.editCustomer(this.savedValues);
    //   console.log('Save')
    // }
  }

  openAddConfirmDialog() {
    const dialogRef = this.dialog.open(CourseAddConfirmDialog);
    //this.firebaseService.selected = customer;

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      this.firebaseService.newCourse.courseID = "";
      this.firebaseService.newCourse.courseName = "";
      this.firebaseService.newCourse.courseCredit = "";
      this.firebaseService.newCourse.courseRef = "";
      this.firebaseService.newCourse.startDate = "";
      this.firebaseService.newCourse.endDate = "";
      this.firebaseService.newCourse.courseStatus = "";
      this.firebaseService.newCourse.professorName = "";
      this.firebaseService.newCourse.horasGeneral = "";
      this.firebaseService.newCourse.horasEtica = "";
      this.firebaseService.newCourse.horasNotaria = "";
      this.firebaseService.newCourse.topic = "";
      this.firebaseService.newCourse.activationType = "";
      //window.location.reload();
    });
  }

}

//--------------------------------------------------------Add Course Confirmation Dialog------------------------------------

@Component({
  selector: 'courses-add-confirm-dialog',
  templateUrl: 'courses-add-confirm-dialog.html',
})
export class CourseAddConfirmDialog {

  constructor(
    public firebaseService: FirebaseService,
  ) { }


} 