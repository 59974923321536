<body>
<div class="main-div">
<div class="example-card">
<!-- <mat-card class="example-card"> -->
  <!-- <mat-card-header> -->
    <!-- <div style="text-align: center;" mat-card-avatar class="example-header-image"><h3><mat-icon>update</mat-icon></h3></div> -->
    <div style="text-align: center;line-height: 3px;vertical-align: middle;"><h1><mat-icon color="primary">update</mat-icon> &nbsp; Digital Chalk Course History</h1></div>
    <div style="text-align: center;line-height: 3px;"><h2>Sync Registrations</h2></div>
    <!-- <mat-card-title>Digital Chalk Course History</mat-card-title>
    <mat-card-subtitle>Sync New Registrations</mat-card-subtitle> -->
  <!-- </mat-card-header> -->
  <!-- <mat-card-content style="text-align: center;margin-top:7%;"> -->
    <p style="text-align: center;line-height: 3px;">
        <mat-form-field appearance="fill">
        <mat-label>Select Month</mat-label>
        <mat-select [(ngModel)]="selectedMonth" required>
            <mat-option value="01">January</mat-option>
            <mat-option value="02">February</mat-option>
            <mat-option value="03">March</mat-option>
            <mat-option value="04">April</mat-option>
            <mat-option value="05">May</mat-option>
            <mat-option value="06">June</mat-option>
            <mat-option value="07">July</mat-option>
            <mat-option value="08">August</mat-option>
            <mat-option value="09">September</mat-option>
            <mat-option value="10">October</mat-option>
            <mat-option value="11">November</mat-option>
            <mat-option value="12">December</mat-option>
        </mat-select>
        </mat-form-field>
    </p>
    <p style="text-align: center;line-height: 3px;">
        <mat-form-field appearance="fill">
        <mat-label>Select Year</mat-label>
        <mat-select [(ngModel)]="selectedYear" required>
            <mat-option *ngFor="let year of years" [value]="year">{{year}}</mat-option>
        </mat-select>
        </mat-form-field>
    </p>
  <!-- </mat-card-content> -->
  <!-- <mat-card-actions style="text-align: center;"> -->
    <div style="text-align: center;"> 
    <button mat-flat-button color="primary" style="text-align: center;" (click)="syncRegistrations(selectedMonth,selectedYear)">Start Sync</button>
    </div> 
  <!-- </mat-card-actions> -->
<!-- </mat-card> -->
</div>    
</div>
</body>