<!-- <mat-toolbar color="primary">
    <mat-toolbar-row>
        <span>Transactions</span>
    </mat-toolbar-row>
</mat-toolbar> -->
<mat-toolbar color="primary">
    <span>Transactions</span>
    <span style="flex: 1 1 auto;"></span>
          
    <span>
        <button mat-icon-button (click)="openAddCreditsDialog()">
            <mat-icon>add</mat-icon>
        </button>
    </span>
</mat-toolbar>
<mat-dialog-content style="width: 100%;">
<div class="mat-elevation-z8">
    <div>
        <span style="float:left;padding-left:17px;padding-top:11px;"><h3>Current Credit Balance: <span style="color:midnightblue;font-weight:bold;">{{customerCredits}}</span></h3></span>
        <!--this.firebaseService.selected.credits-->
        <!-- <span style="float:right;"><button mat-flat-button color="primary">Add Transaction (Manually)</button></span> -->
    </div>
    <table mat-table [dataSource]="dataSource" style="width: 100%;" matSort>
  
      <!-- Transaction ID Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Transaction ID </th>
        <td mat-cell *matCellDef="let transaction"> {{transaction.id}} </td>
      </ng-container>
  
      <!-- Transaction Date Column -->
      <ng-container matColumnDef="creditBalanceDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Transaction Date </th>
        <td mat-cell *matCellDef="let transaction"> {{transaction.creditBalanceDate}} </td>
      </ng-container>
  
      <!-- Credits Column -->
      <ng-container matColumnDef="credits">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Credits </th>
        <td mat-cell *matCellDef="let transaction"> {{transaction.credits}} </td>
      </ng-container>
  
      <!-- Net Total Column -->
      <ng-container matColumnDef="netTotal">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Net Total </th>
        <td mat-cell *matCellDef="let transaction"> {{transaction.netTotal}} </td>
      </ng-container>

        <!-- IVU Amount Column -->
        <ng-container matColumnDef="ivuAmount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> IVU Amount </th>
            <td mat-cell *matCellDef="let transaction"> {{transaction.ivuAmount}} </td>
        </ng-container>

        <!-- Total Column -->
        <ng-container matColumnDef="total">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Total </th>
            <td mat-cell *matCellDef="let transaction"> {{transaction.total}} </td>
        </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>OK</button>
</mat-dialog-actions>