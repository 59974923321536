<!-- <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource">
      
          <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef> No. </th>
            <td mat-cell *matCellDef="let element"> {{element.position}} </td>
          </ng-container>
      
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>

          <ng-container matColumnDef="weight">
            <th mat-header-cell *matHeaderCellDef> Weight </th>
            <td mat-cell *matCellDef="let element"> {{element.weight}} </td>
          </ng-container>
      
          <ng-container matColumnDef="symbol">
            <th mat-header-cell *matHeaderCellDef> Symbol </th>
            <td mat-cell *matCellDef="let element"> {{element.symbol}} </td>
          </ng-container>
      
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
      </div> -->
      <form class="example-form">
        <mat-form-field class="example-full-width">
          <mat-label>Search</mat-label>
          <input matInput (keyup)="applyFilter($event.target.value)">
        </mat-form-field>
      </form>

      <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>

          <ng-container matColumnDef="options">
              <th mat-header-cell *matHeaderCellDef> <button mat-icon-button color="primary" (click)="openNewDialog()"><mat-icon>add_box</mat-icon></button> </th>
              <td mat-cell *matCellDef="let customer">
                <!-- <button mat-icon-button color="primary">
                  <mat-icon>settings</mat-icon>
                </button> -->
                <button mat-icon-button [matMenuTriggerFor]="menu" color="primary">
                  <mat-icon>settings</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="openEditDialog(customer)"> <!--(click)="onEdit(customer)"-->
                    <mat-icon>edit</mat-icon>
                    <span>Edit</span>
                  </button>
                  <button mat-menu-item *ngIf="isCanDelete" (click)="onDelete(customer.id)"> <!--disabled-->
                    <mat-icon>delete</mat-icon>
                    <span>Delete</span>
                  </button>
                  <!-- <button mat-menu-item>
                    <mat-icon>notifications_off</mat-icon>
                    <span>Disable alerts</span>
                  </button> -->
                </mat-menu>                
          </ng-container>      
      
          <ng-container matColumnDef="firstName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> First Name </th>
            <td mat-cell *matCellDef="let customer"> {{customer.firstName}} </td>
          </ng-container>
      
          <ng-container matColumnDef="initial">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Initial </th>
            <td mat-cell *matCellDef="let customer"> {{customer.initial}} </td>
          </ng-container>

          <ng-container matColumnDef="lastNameOne">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Paternal Last Name </th>
            <td mat-cell *matCellDef="let customer"> {{customer.lastNameOne}} </td>
          </ng-container>
      
          <ng-container matColumnDef="lastNameTwo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Maternal Last Name </th>
            <td mat-cell *matCellDef="let customer"> {{customer.lastNameTwo}} </td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
            <td mat-cell *matCellDef="let customer"> {{customer.email}} </td>
        </ng-container>          

            <ng-container matColumnDef="rua">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> RUA </th>
                <td mat-cell *matCellDef="let customer"> {{customer.rua}} </td>
            </ng-container>        

            <ng-container matColumnDef="ruaisValid">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> RUA Validated? </th>
                <td mat-cell *matCellDef="let customer"> {{customer.ruaisValid}} </td>
            </ng-container>

            <ng-container matColumnDef="credits">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Credit Balance Available </th>
                <td mat-cell *matCellDef="let customer"> {{customer.credits}} </td>
            </ng-container>                

            <ng-container matColumnDef="creditBalanceDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Credit Balance Date </th>
                <td mat-cell *matCellDef="let customer"> {{customer.creditBalanceDate}} </td>
            </ng-container>
            
            <ng-container matColumnDef="actionOne">
              <th mat-header-cell *matHeaderCellDef>  </th>
              <td mat-cell *matCellDef="let customer">
                <button mat-icon-button (click)="openTransactionsDialog(customer)">
                  <mat-icon>credit_card</mat-icon>
                </button>
              </td>
            </ng-container>

          <ng-container matColumnDef="actionTwo">
            <th mat-header-cell *matHeaderCellDef>  </th>
            <td mat-cell *matCellDef="let customer">
              <button mat-icon-button (click)="openCoursesDialog(customer)">
                <mat-icon>class</mat-icon>
              </button>
            </td>
          </ng-container>
      
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      
        <mat-paginator [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons></mat-paginator>
      </div>

      <!-- <div class="example-container mat-elevation-z8">
            <div class="example-header">
              <mat-form-field>
                <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
              </mat-form-field>
            </div>
          
            <table mat-table [dataSource]="dataSource">              
          
              <ng-container matColumnDef="firstName">
                <mat-header-cell *matHeaderCellDef mat-sort-header> First Name </mat-header-cell>
                <mat-cell *matCellDef="let user"> {{ user.firstName }} </mat-cell>
              </ng-container>
          
          
              <ng-container matColumnDef="initial">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Initial </mat-header-cell>
                <mat-cell *matCellDef="let user"> {{ user.initial }} </mat-cell>
              </ng-container>
          
          
              <ng-container matColumnDef="lastNameOne">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Paternal Last Name </mat-header-cell>
                <mat-cell *matCellDef="let user"> {{ user.lastNameOne }} </mat-cell>
              </ng-container>
          
          
              <ng-container matColumnDef="lastNameTwo">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Maternal Last Name </mat-header-cell>
                <mat-cell *matCellDef="let user"> {{ user.lastNameOne }} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="email">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Email </mat-header-cell>
                <mat-cell *matCellDef="let user"> {{ user.email }} </mat-cell>
            </ng-container>             

            <ng-container matColumnDef="rua">
                <mat-header-cell *matHeaderCellDef mat-sort-header> RUA </mat-header-cell>
                <mat-cell *matCellDef="let user"> {{ user.rua }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="ruaisValid">
                <mat-header-cell *matHeaderCellDef mat-sort-header> RUA Validated? </mat-header-cell>
                <mat-cell *matCellDef="let user"> {{ user.ruaisValid }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="credits">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Credit Balance Available </mat-header-cell>
                <mat-cell *matCellDef="let user"> {{ user.credits }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="creditBalanceDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Credit Balance Date </mat-header-cell>
                <mat-cell *matCellDef="let user"> {{ user.creditBalanceDate }} </mat-cell>
            </ng-container>
          
              <ng-container matColumnDef="edit">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Edit </mat-header-cell>
                  <mat-cell *matCellDef="let user"> 
                    <button mat-raised-button  color="primary" (click)="openDialog(user)">Edit</button> 
                  </mat-cell>
              </ng-container>
          
              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;" class="animate"></mat-row>
            </table>
          </div> -->