import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import {FirebaseService} from './firebase.service';
import {AngularFireAuth} from '@angular/fire/auth';
import {environment} from '../../environments/environment';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { User, Roles } from '../services/user';
import {AngularFirestore} from '@angular/fire/firestore';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  successEmailSent: boolean = false;
  errorMsg: any;

  userData: any;

  //user: Observable<User>;

  //userData: Observable<firebase.User>;

  showUnauthorizedAlert: boolean = false;

  constructor(
    private firebaseService: FirebaseService,
    public afAuth: AngularFireAuth,
    private router: Router,
    private afs: AngularFirestore, 
  ) { 
    //this.user = afAuth.authState;
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.userData = user;
        //console.log("this.userData", this.userData)
        // localStorage.setItem('user', JSON.stringify(this.userData));
        // JSON.parse(localStorage.getItem('user'));
      } else {
        // localStorage.setItem('user', null);
        // JSON.parse(localStorage.getItem('user'));
      }
    })
  }

  // login(value) {
  //   this.afAuth.auth.signInWithEmailAndPassword(value.email, value.password)
  //   .then(value => {
  //     console.log('Welcome!');
  //     this.router.navigateByUrl('/customers');
  //   })
  //   .catch(err => {
  //     console.log('Something went wrong: ', err.message);
  //   });
  // }

  // isLoggedIn() {
  //   return this.afAuth.authState.pipe(first()).toPromise();
  // }

  // authState(){
  //   this.user = this.afAuth.authState.pipe(first());
  // }

  // currentUserValue(){
  //   return this.currentUser = this.afAuth.authState;
  //   //return this.afAuth.authState.pipe(first()).toPromise();
  // }

  login(value) {
    return new Promise<any>((resolve, reject) => {
      firebase.auth().signInWithEmailAndPassword(value.email, value.password)
        .then((res) => {


          this.afs.collection('userProfiles').doc(`${value.email}`).valueChanges().subscribe(data =>{
            //('data: ', data)
            //console.log('data.deleteAdmin:', data["deleteAdmin"])
            if (!data || res.user.uid != data["uid"]) {
              this.showUnauthorizedAlert = true;
              this.logout();
              this.router.navigate(['/']);
          } else if (data["role"] == 'User') {
              console.error('Access denied - Admins only')
              this.showUnauthorizedAlert = true;
              this.logout();
              this.router.navigate(['/']);
          }
          });


          console.log('Welcome!');
          resolve();
        }).catch((error) => {
        console.log(error);
        window.alert(error.message)
        reject();
      });
    });
  }

  logout() {
    return new Promise((resolve, reject) => {
      this.afAuth.auth.signOut()
        .then(() => {
          console.log('Logged Out!')
          resolve();
        }).catch((error) => {
        console.log(error);
        reject();
      });
    });
  }

  resetPassword(email: string): Promise<any> {
    return this.afAuth.auth.sendPasswordResetEmail(email).catch((e) => {
        this.errorMsg = e.message;
    }).then((x) => {
        this.successEmailSent = true;
      });
  } 

  // logout() {
  //   this.afAuth.auth.signOut().then(() => {
  //     this.router.navigate(['/']);
  //   });
  // }

}
