<h2 mat-dialog-title>Add Manual Credits Transaction</h2>
<mat-dialog-content class="mat-typography">
<div>
    <mat-form-field appearance="fill">
        <mat-label>Credits</mat-label>
        <input matInput name="credits" [(ngModel)]="this.firebaseService.newCredit.credits">
    </mat-form-field>

    <mat-form-field appearance="fill" style="margin-left: 7px;">
        <mat-label>Credits Cost</mat-label>
        <input matInput name="costPerCredit" [(ngModel)]="this.firebaseService.newCredit.costPerCredit">
    </mat-form-field>
</div>    
<!-- <button mat-button mat-dialog-close>Cancel</button>
<button mat-button [mat-dialog-close]="true" cdkFocusInitial>Save</button> -->
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial (click)="addManualCredits()">Save</button>
</mat-dialog-actions>