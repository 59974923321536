import { Injectable } from '@angular/core';
import {AngularFirestore,AngularFirestoreCollection} from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
//import * as firebase from 'firebase';
import 'firebase/storage';
import {AngularFireAuth} from '@angular/fire/auth';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {CustomersI} from '../customers/customers';
import {CreditsI} from '../credits/credits';
import {CourseRequestsI} from '../courserequests/courerequests';
import {CoursesI} from '../courses/courses';
import {UsersI} from '../users/users';
import {DigitalChalkI} from '../digitalchalk/digitalchalk';

export interface CustomerID extends CustomersI {id: string;}
export interface CreditsID extends CreditsI {id: string;}
export interface CourseRequestsID extends CourseRequestsI {id: string;}
export interface CoursesID extends CoursesI {id: string;}
export interface UserID extends UsersI {id: string;}
export interface DCID extends DigitalChalkI {id: string;}

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  private snapshotChangesSubscription: any;

  private customerCollection: AngularFirestoreCollection<CustomersI>;
  customers: Observable<CustomersI>;
  userCustomers: any;

  private creditsCollection: AngularFirestoreCollection<CreditsI>;
  credits: Observable<CreditsI>;
  userCredits: any;

  private courseRequestsCollection: AngularFirestoreCollection<CourseRequestsI>;
  courseRequests: Observable<CourseRequestsI>;
  userCourseRequests: any;

  private coursesCollection: AngularFirestoreCollection<CoursesI>;
  courses: Observable<CoursesI>;
  coursesList: any;

  private usersCollection: AngularFirestoreCollection<UsersI>;
  users: Observable<UsersI>;
  userEmployees: any;

  private dcCollection: AngularFirestoreCollection<DigitalChalkI>;
  registrations: Observable<DigitalChalkI>;
  dcRegistrations: any;

  public selected = {
    id:null,
    firstName:'',
    initial:'',
    lastNameOne:'',
    lastNameTwo:'',
    email:'',
    password:'',
    mobile:'',
    lawyerConf:'',
    rua:'',
    displayName:'',
    superAdmin:'',
    ruaisValid:'',
    Admin:'',
    deleteAdmin:'',
    role:'',
    profileType:'',
    uid:'',
    credits:'',
    creditBalanceDate:'',
    digitalchalkID:'',
    creditIncr: '',
    productType: '',
    customerRegistrationDate: '',
    customerRegistrationDay: '',
    nextIncrDate: '',
  }

  public newCustomer = {
    id:null,
    firstName:'',
    initial:'',
    lastNameOne:'',
    lastNameTwo:'',
    email:'',
    password:'',
    mobile:'',
    lawyerConf:'',
    rua:'',
    displayName:'',
    superAdmin:'',
    ruaisValid:'',
    Admin:'',
    deleteAdmin:'',
    role:'',
    profileType:'',
    uid:'',
    credits:'',
    creditBalanceDate:'',
    creditIncr: '',
    productType: '',
    customerRegistrationDate: '',
    customerRegistrationDay: '',
    nextIncrDate: '',
  }

  public course = {
    id:null,
    courseID:'',
    digitalchalkID:'',
    email:'',
    registrationID:''
  }

  public selectedCredit = {
    id:null,
    total:'',
    credits:'',
    firstName:'',
    initial:'',
    lastNameOne:'',
    lastNameTwo:'',
    memo:'',
    mobile:'',
    email:'',
    rua:'',
    lawyerConf:'',
    uid_FK:'',
    creditsBalanceDate:'',
    creditsFIFOBalance:'',
    ivuAmount:'',
    netTotal:'',
    purchaseType:'',
    status:'',
    typeOfTransaction:'',
    emailStatus:''
  }

  public newCredit = {
    id:null,
    total:'',
    credits:'',
    firstName:'',
    initial:'',
    lastNameOne:'',
    lastNameTwo:'',
    memo:'',
    mobile:'',
    email:'',
    rua:'',
    lawyerConf:'',
    uid_FK:'',
    creditsBalanceDate:'',
    creditsFIFOBalance:'',
    ivuAmount:'',
    netTotal:'',
    purchaseType:'',
    status:'',
    typeOfTransaction:'',
    costPerCredit:'',
    emailStatus:''
  }

 public selectedCourseRequest = {
    CostPerCreditFIFO:'',
    FIFOCostCalculated:'',
    FIFOCostPerCredit:'',
    courseID:'',
    courseName:'',
    courseRef:'',
    credits:'',
    digitalchalkEmail:'',
    digitalchalkID:'',
    email:'',
    fifoStatus:'',
    firstName:'',
    id: null,
    initial:'',
    lastNameOne:'',
    lastNameTwo:'',
    mobile:'',
    professorName:'',
    purchaseType:'',
    queueCounter:'',
    registrationDate:'',
    registrationID:'',
    registrationStatus:'',
    rua:'',
    status:'',
    statusMessage:'',
    title:'',
    emailStatus: ''
  }

  public newCourseRequest = {
    CostPerCreditFIFO:'',
    FIFOCostCalculated:'',
    FIFOCostPerCredit:'',
    courseID:'',
    courseName:'',
    courseRef:'',
    credits:'',
    digitalchalkEmail:'',
    digitalchalkID:'',
    email:'',
    fifoStatus:'',
    firstName:'',
    id: null,
    initial:'',
    lastNameOne:'',
    lastNameTwo:'',
    mobile:'',
    professorName:'',
    purchaseType:'',
    queueCounter:'',
    registrationDate:'',
    registrationID:'',
    registrationStatus:'',
    rua:'',
    status:'',
    statusMessage:'',
    title:'',
    emailStatus:''
  }

  selectedCourse = {
    id:null,
    courseCredit:'',
    courseID:'',
    courseName:'',
    courseRef:'',
    courseStatus:'',
    endDate:'',
    horasEtica:'',
    horasGeneral:'',
    horasNotaria:'',
    professorName:'',
    startDate:'',
    title:'',
    topic:'',
    activationType:''
  }

  newCourse = {
    id:null,
    courseCredit:'',
    courseID:'',
    courseName:'',
    courseRef:'',
    courseStatus:'',
    endDate:'',
    horasEtica:'',
    horasGeneral:'',
    horasNotaria:'',
    professorName:'',
    startDate:'',
    title:'',
    topic:'',
    activationType:''
  }

  selectedUser = {
    id:null,
    Admin:'',
    deleteAdmin:'',
    displayName:'',
    email:'',
    firstName:'',
    initial:'',
    lastNameOne:'',
    lastNameTwo:'',
    mobile:'',
    password:'',
    profileType:'',
    role:'',
    superAdmin:'',
    uid:'',
  }

  newUser = {
    id:null,
    Admin:'',
    deleteAdmin:'',
    displayName:'',
    email:'',
    firstName:'',
    initial:'',
    lastNameOne:'',
    lastNameTwo:'',
    mobile:'',
    password:'',
    profileType:'',
    role:'',
    superAdmin:'',
    uid:'',
  }

  constructor(
    //public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    private readonly afs: AngularFirestore,
  ) { 
    //----------- Customers ------------
    this.userCustomers = this.customers;
    this.customerCollection = afs.collection<CustomersI>('userProfiles', ref => ref.where('profileType', '==', 'Customer'));
    this.userCustomers = this.customerCollection.snapshotChanges().pipe(
      map(actions => actions.map (a => {
        const data = a.payload.doc.data() as CustomersI;
        const id = a.payload.doc.id;
        return {id, ... data};
      }))
    )

    //----------- Credits ------------
    this.userCredits = this.credits;
    this.creditsCollection = afs.collection<CreditsI>('creditsFormstack');
    this.userCredits = this.creditsCollection.snapshotChanges().pipe(
      map(actions => actions.map ( a => {
        const data = a.payload.doc.data() as CreditsI;
        const id = a.payload.doc.id;
        return {id, ... data};
      }))
    )

    //----------- Course Requests ------------
    this.userCourseRequests = this.courseRequests;
    this.courseRequestsCollection = afs.collection<CourseRequestsI>('coursesFormstack');
    this.userCourseRequests = this.courseRequestsCollection.snapshotChanges().pipe(
      map(actions => actions.map ( a => {
        const data = a.payload.doc.data() as CourseRequestsI;
        const id = a.payload.doc.id;
        return {id, ... data};
      }))
    )

    //----------- Courses ------------
    this.coursesList = this.courses;
    this.coursesCollection = afs.collection<CoursesI>('masterListCourses');
    this.coursesList = this.coursesCollection.snapshotChanges().pipe(
      map(actions => actions.map ( a => {
        const data = a.payload.doc.data() as CoursesI;
        const id = a.payload.doc.id;
        return {id, ... data};
      }))
    )

    //----------- Users ------------
    this.userEmployees = this.users;
    this.usersCollection = afs.collection<UsersI>('userProfiles', ref => ref.where('profileType', '==', 'Employee'));
    this.userEmployees = this.usersCollection.snapshotChanges().pipe(
      map(actions => actions.map ( a => {
        const data = a.payload.doc.data() as UsersI;
        const id = a.payload.doc.id;
        return {id, ... data};
      }))
    )

    //----------- DC Registrations ------------
    this.dcRegistrations = this.users;
    this.dcCollection = afs.collection<DigitalChalkI>('digitalChalkRegistrations');
    this.dcRegistrations = this.dcCollection.snapshotChanges().pipe(
      map(actions => actions.map ( a => {
        const data = a.payload.doc.data() as DigitalChalkI;
        const id = a.payload.doc.id;
        return {id, ... data};
      }))
    )
  }

  unsubscribeOnLogOut() {
    this.snapshotChangesSubscription.unsubscribe();
  }

  //--------------------------------- Customers ---------------------------------

  getAllCustomers(){
    return this.userCustomers;
  }

  editCustomer(customer:CustomerID){
    //console.log(customer)
    //let id = '';
    return this.customerCollection.doc(customer.id).update(customer);
  }

  deleteCustomer(id: string){
    return this.customerCollection.doc(id).delete();
  }

  addCustomer(customer:CustomersI){
    // return this.customerCollection.add(customer);
    return this.customerCollection.doc(customer.email).set(customer);
  }

  //--------------------------------- Credits ---------------------------------
  
  getAllCredits(){
    return this.userCredits;
  }

  editCredits(credit:CreditsID){
    //console.log(credit)
    return this.creditsCollection.doc(credit.id).update(credit);
  }

  deleteCredit(id: string){
    return this.creditsCollection.doc(id).delete();
  }

  addCredit(credit:CreditsI){
    return this.creditsCollection.add(credit);
  }

  //--------------------------------- Course Requests ---------------------------------
  
  getAllCourseRequests(){
    return this.userCourseRequests;
  }

  editCourseRequests(courseReq:CourseRequestsID){
    //console.log(courseReq)
    return this.courseRequestsCollection.doc(courseReq.id).update(courseReq);
  }

  deleteCourseRequests(id: string){
    return this.courseRequestsCollection.doc(id).delete();
  }

  addCourseRequests(courseReq:CourseRequestsI){
    return this.courseRequestsCollection.doc(courseReq.id).set(courseReq);
  }

  //--------------------------------- Courses ---------------------------------
  
  getAllCourses(){
    return this.coursesList;
  }

  editCourses(course:CoursesID){
    //console.log(course)
    return this.coursesCollection.doc(course.id).update(course);
  }

  deleteCourses(id: string){
    return this.coursesCollection.doc(id).delete();
  }

  addCourses(course:CoursesI){
    return this.coursesCollection.doc(course.courseID).set(course);
  }

  //--------------------------------- Users ---------------------------------
  
  getAllUsers(){
    return this.userEmployees;
  }

  editUsers(user:UserID){
    //console.log(user)
    return this.usersCollection.doc(user.id).update(user);
  }

  deleteUsers(id: string){
    return this.usersCollection.doc(id).delete();
  }

  addUsers(user:UsersI){
    return this.usersCollection.doc(user.email).set(user);
  }

  //--------------------------------- Users ---------------------------------

  addRegistrations(registration:DigitalChalkI){
    return this.dcCollection.doc(registration.registrationID).set(registration);
  }

}
