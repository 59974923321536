import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { HttpClientModule,HttpClient } from '@angular/common/http';
import { AgGridModule } from 'ag-grid-angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import {AngularFireModule} from '@angular/fire';
import {AngularFirestoreModule, FirestoreSettingsToken} from '@angular/fire/firestore';
import {AngularFireStorageModule} from '@angular/fire/storage';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFireAuth} from '@angular/fire/auth';

import {environment} from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {A11yModule} from '@angular/cdk/a11y';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {PortalModule} from '@angular/cdk/portal';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatStepperModule} from '@angular/material/stepper';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTreeModule} from '@angular/material/tree';

import { AuthService } from '../app/services/auth.service';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { CustomersComponent } from './customers/customers.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { CreditsComponent } from './credits/credits.component';
import { CourserequestsComponent } from './courserequests/courserequests.component';
import { CoursesComponent } from './courses/courses.component';
import { UsersComponent } from './users/users.component';
import { CustomerEditDialog } from './customers/customers.component';
import { CreditsEditDialog } from './credits/credits.component';
import { CourserequestEditDialog } from './courserequests/courserequests.component';
import { CoursesEditDialog } from './courses/courses.component';
import { UserEditDialog } from './users/users.component';
import { CustomersEditDialogComponent } from './customers-edit-dialog/customers-edit-dialog.component';
import { CustomerNewDialog } from './customers/customers.component';
import { CustomerAddConfirmDialog } from './customers/customers.component';
import { CustomerTransactions } from './customers/customers.component';
import { CustomerCourses } from './customers/customers.component';
import { CustomerAddCreditsDialog } from './customers/customers.component';
import { CustomerCoursesHistory } from './customers/customers.component';
import { CourserequestNewDialog } from './courserequests/courserequests.component';
import { CourseRequestAddConfirmDialog } from './courserequests/courserequests.component';
import { CourseNewDialog } from './courses/courses.component';
import { CourseAddConfirmDialog } from './courses/courses.component';
import { UserNewDialog } from './users/users.component';
import { UserAddConfirmDialog } from './users/users.component';
import { CustomerAddCreditsConfirmDialog } from './customers/customers.component';
import { DigitalchalkComponent } from './digitalchalk/digitalchalk.component';
import { ReportsComponent } from './reports/reports.component';
import { RegistrationsSyncConfirmDialog } from './digitalchalk/digitalchalk.component';
import { SupremoDialog } from './reports/reports.component';
import { RegaliaDialog } from './reports/reports.component';
import { EjectConfirmDialog } from './customers/customers.component';
import { ResetPasswordDialog } from './login/login.component';

@NgModule({
  declarations: [
    AppComponent,
    CustomersComponent,
    LoginComponent,
    HomeComponent,
    CreditsComponent,
    CourserequestsComponent,
    CoursesComponent,
    UsersComponent,
    CustomerEditDialog,
    CreditsEditDialog,
    CourserequestEditDialog,
    CoursesEditDialog,
    UserEditDialog,
    CustomersEditDialogComponent,
    CustomerNewDialog,
    CustomerAddConfirmDialog,
    CustomerTransactions,
    CustomerCourses,
    CustomerAddCreditsDialog,
    CustomerCoursesHistory,
    CourserequestNewDialog,
    CourseRequestAddConfirmDialog,
    CourseNewDialog,
    CourseAddConfirmDialog,
    UserNewDialog,
    UserAddConfirmDialog,
    CustomerAddCreditsConfirmDialog,
    DigitalchalkComponent,
    ReportsComponent,
    RegistrationsSyncConfirmDialog,
    SupremoDialog,
    RegaliaDialog,
    EjectConfirmDialog,
    ResetPasswordDialog
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule.enablePersistence(),
    AngularFireAuthModule,
    AngularFireStorageModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    A11yModule,
    ClipboardModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    PortalModule,
    ScrollingModule,
    HttpClientModule,
    AgGridModule.withComponents([])
  ],
  providers: [AuthService,HttpClient],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class AppModule { }
