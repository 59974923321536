<h2 mat-dialog-title>Recover Password</h2>
<mat-dialog-content class="mat-typography">
<div>
    <mat-form-field appearance="fill">
        <mat-label>Email</mat-label>
        <input matInput [(ngModel)]='email' name='email'>
    </mat-form-field>
</div>    
<!-- <button mat-button mat-dialog-close>Cancel</button>
<button mat-button [mat-dialog-close]="true" cdkFocusInitial>Save</button> -->
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial [disabled]="!email" (click)="authService.resetPassword(email)">Submit</button>
</mat-dialog-actions>