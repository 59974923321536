import { Component, OnInit } from '@angular/core';

import {FormControl, Validators, FormBuilder, FormGroup} from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from '../services/auth.service';

import {AngularFireAuth} from '@angular/fire/auth';

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  validations_form: FormGroup;
  errorMessage = '';
  successEmailSent: boolean = false;
  errorMsg: any;

  showUnauthorizedAlert: boolean = false;

  validation_messages = {
    'email': [
      {type: 'required', message: 'Email is required.'},
      {type: 'pattern', message: 'Please enter a valid email.'}
    ],
    'password': [
      {type: 'required', message: 'Password is required.'},
    ]
  };

  constructor(
    public authService: AuthService,
    private formBuilder: FormBuilder,
    private router: Router,
    public afAuth: AngularFireAuth,
    public dialog: MatDialog,
  ) { }

  title = 'microjuris-gateway-mjcle-vtwo-four';

  hide = true;

  email = new FormControl('', [Validators.required, Validators.email]);

  ngOnInit(): void {
    this.validations_form = this.formBuilder.group({
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])),
      password: new FormControl('', Validators.compose([
        Validators.required
      ])),
    });
  }

  getErrorMessage() {
    if (this.email.hasError('required')) {
      return 'You must enter a value';
    }

    return this.email.hasError('email') ? 'Not a valid email' : '';
  }

  closeAlert(){
    this.authService.showUnauthorizedAlert = false;
  }

  // onSubmit(value) {
  //   if (value.valid) {
  //     console.log(value);
  //     this.authService.login(value
  //     );
  //   }
  // }

  tryLogin(value) {
    this.authService.login(value)
      .then(res => {
        this.router.navigateByUrl('/home');
      }, err => {
        this.errorMessage = err.message;
        console.log(err);
      });
  }

  openResetPasswordDialog() {
    const dialogRef = this.dialog.open(ResetPasswordDialog);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      window.location.reload();
    });
  }

}

  //--------------------------------------------------------Reset Password Dialog------------------------------------

  @Component({
    selector: 'reset-password-dialog',
    templateUrl: 'reset-password-dialog.html',
  })
  export class ResetPasswordDialog {

    email: any;
  
    constructor(
      public authService: AuthService,
      private formBuilder: FormBuilder,
    ) { }
  
  
  } 
