<form class="example-form">
    <mat-form-field class="example-full-width">
        <mat-label>Search</mat-label>
        <input matInput (keyup)="applyFilter($event.target.value)">
    </mat-form-field>
</form>

<div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="options">
                <th mat-header-cell *matHeaderCellDef> <button mat-icon-button color="primary" (click)="openNewDialog()"><mat-icon>add_box</mat-icon></button> </th>
                <td mat-cell *matCellDef="let course">
                    <!-- <button mat-icon-button color="primary">
                        <mat-icon>settings</mat-icon>
                    </button> -->
                    <button mat-icon-button [matMenuTriggerFor]="menu" color="primary">
                        <mat-icon>settings</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="openEditDialog(course)">
                            <mat-icon>edit</mat-icon>
                            <span>Edit</span>
                        </button>
                        <button mat-menu-item *ngIf="isCanDelete" (click)="onDelete(course.id)"> <!--disabled-->
                            <mat-icon>delete</mat-icon>
                            <span>Delete</span>
                        </button>
                        <!-- <button mat-menu-item>
                            <mat-icon>notifications_off</mat-icon>
                            <span>Disable alerts</span>
                        </button> -->
                    </mat-menu>                    
            </ng-container>            

            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Transaction ID </th>
                <td mat-cell *matCellDef="let course"> {{course.id}} </td>
            </ng-container>            
      
          <ng-container matColumnDef="firstName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> First Name </th>
            <td mat-cell *matCellDef="let course"> {{course.firstName}} </td>
          </ng-container>

          <ng-container matColumnDef="initial">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Initial </th>
            <td mat-cell *matCellDef="let course"> {{course.initial}} </td>
        </ng-container>

          <ng-container matColumnDef="lastNameOne">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Paternal Last Name </th>
            <td mat-cell *matCellDef="let course"> {{course.lastNameOne}} </td>
          </ng-container>
      
          <ng-container matColumnDef="lastNameTwo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Maternal Last Name </th>
            <td mat-cell *matCellDef="let course"> {{course.lastNameTwo}} </td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
            <td mat-cell *matCellDef="let course"> {{course.email}} </td>
        </ng-container>

        <ng-container matColumnDef="mobile">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Phone </th>
            <td mat-cell *matCellDef="let course"> {{course.mobile}} </td>
        </ng-container>        

            <ng-container matColumnDef="rua">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> RUA </th>
                <td mat-cell *matCellDef="let course"> {{course.rua}} </td>
            </ng-container>

            <ng-container matColumnDef="credits">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Credit Amount </th>
                <td mat-cell *matCellDef="let course"> {{course.credits}} </td>
            </ng-container>             

            <ng-container matColumnDef="courseRef">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Course Ref </th>
                <td mat-cell *matCellDef="let course"> {{course.courseRef}} </td>
            </ng-container>                

            <ng-container matColumnDef="courseName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Course Name </th>
                <td mat-cell *matCellDef="let course"> {{course.courseName}} </td>
            </ng-container>         
            
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Transaction Status </th>
                <td mat-cell *matCellDef="let course"> {{course.status}} </td>
            </ng-container> 

            <ng-container matColumnDef="registrationStatus">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Registration Status </th>
                <td mat-cell *matCellDef="let course"> {{course.registrationStatus}} </td>
            </ng-container> 

            <ng-container matColumnDef="statusMessage">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Status Message </th>
                <td mat-cell *matCellDef="let course"> {{course.statusMessage}} </td>
            </ng-container>            

            <ng-container matColumnDef="emailStatus">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Email Status </th>
                <td mat-cell *matCellDef="let course"> {{course.emailStatus}} </td>
            </ng-container>
      
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      
        <mat-paginator [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons></mat-paginator>
      </div>
