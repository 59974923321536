import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {AngularFireAuth} from '@angular/fire/auth';
import {User} from 'firebase';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  user: User;

  constructor(
    private router: Router,
    private authService: AuthService,
    public afAuth: AngularFireAuth,
  ) { }

  ngOnInit(): void {
    this.getUser();
  }

  getUser() {
    this.afAuth.user.subscribe(user => {
      this.user = user;
    });
  }

  logout() {
    this.authService.logout()
      .then(res => {
        this.router.navigateByUrl('/login');
      }, err => {
        console.log(err);
      });
  }

}
