import { Component, OnInit } from '@angular/core';
import {FirebaseService} from '../services/firebase.service';
import {Grid} from "ag-grid-community";
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import * as firebase from 'firebase';
import {AngularFirestore,AngularFirestoreCollection} from '@angular/fire/firestore';
import 'firebase/storage';
import {AngularFireAuth} from '@angular/fire/auth';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";

import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import { formatDate } from "@angular/common";
import {FormControl} from '@angular/forms';

import * as moment from 'moment';

// let now = moment().format('YYYY-MM-DD');

export const MY_FORMATS = {
  parse: {
    dateInput: ['YYYY-MM-DD'], //LL
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};


firebase.initializeApp({
  apiKey: "AIzaSyCq7eBnHhNb0SlA2_PqIhd-5aPHhBKcdnw",
  authDomain: "microjurisgateway.firebaseapp.com",
  projectId: "microjurisgateway",
});

var db = firebase.firestore();

export interface Reports {
  lawyerID?: number;
  fromDate?: string;
  toDate?: string;
  selectedCourse?: string;
  selectedDate?: string;
}

export interface Section {
  name: string;
  //updated: Date;
}

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class ReportsComponent implements OnInit {

  lawyerID: any;
  fromDate: any;
  toDate: any;
  selectedCourse: any;
  selectedDate: any;

  constructor(
    public firebaseService: FirebaseService,
    public dialog: MatDialog,
    public afAuth: AngularFireAuth,
    private readonly afs: AngularFirestore,
  ) { }

  private reportsCollection: AngularFirestoreCollection<Reports>;
  reports: Observable<Reports>;
  regaliaReports: any;

  ngOnInit(): void {
  }

  reportsOne: Section[] = [
    {
      name: 'Reporte del Supremo',
    }
  ];
  reportsTwo: Section[] = [
    {
      name: 'Reporte de Regalía',
    }
  ];

  openSupremoDialog() {
    const dialogRef = this.dialog.open(SupremoDialog);
    //this.firebaseService.selected = customer;

    this.selectedDate = {
      fromDate: new Date(),
      toDate: new Date()
    }

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  openRegaliaDialog() {
    const dialogRef = this.dialog.open(RegaliaDialog);
    //this.firebaseService.selected = customer;

    //this.fromDate = new FormControl(moment());

    //this.toDate = new FormControl(moment());

    this.selectedDate = {
      fromDate: new Date(),
      toDate: new Date()
    }

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  //---------------------------------------------------------- Bundle Process Testing Starts Here ----------------------------------------------------------

  // bundleTest(){

  //   var userProfilesRef = db.collection("userProfiles").where('productType', '==', 'B');

  //   userProfilesRef.get().then(function(querySnapshot: any) {
  //     querySnapshot.forEach(async function(doc: any) {

  //       // doc.data() is never undefined for query doc snapshots
  //       console.log(doc.id, " => ", doc.data());

  //       var obj = JSON.parse(JSON.stringify(doc.data()));

  //       if(obj.productType === 'B' && (obj.customerRegistrationDate !== '' || obj.customerRegistrationDate !== null || obj.customerRegistrationDate !== undefined) && (obj.creditIncr !== '' || obj.creditIncr !== null || obj.creditIncr !== undefined)){


  //         //get current date
  //         var now = moment().format('YYYY-MM-DDTHH:mm:ss');

  //         var currentDate = now.toString();

  //         var dateSlice = currentDate.slice(8, -9);

  //         var currentDateSlice = dateSlice.toString();

  //         console.log("currentDateSlice: ", currentDateSlice)

  //         var currentDateOnly = currentDate.substring(0,10);

  //         console.log('currentDateOnly: ', currentDateOnly)


  //         //get next incr date
  //         var incrDate = obj.nextIncrDate.toString();

  //         var dateSlice2 = incrDate.slice(8, -9);

  //         var incrDateSlice = dateSlice2.toString();

  //         var incrDateOnly = incrDate.substring(0,10);

  //         console.log('incrDateOnly: ', incrDateOnly)

  //         //get next 30 days
  //         var inThirtyDays = moment().add(30, 'days').format('YYYY-MM-DDTHH:mm:ss');

  //         console.log("inThirtyDays: ", inThirtyDays)

  //         //calculate days between dates

  //         // var a = moment(incrDate);
  //         // var b = moment(currentDate);
  //         // var daysDiff = a.diff(b, 'days')

  //         // console.log('daysDiff: ', daysDiff.toString())

  //         console.log(`${currentDateOnly} === ${incrDateOnly}`)
  //         console.log(currentDateOnly === incrDateOnly)

  //         if(currentDateOnly === incrDateOnly){
  //           console.log('Today IS Increment Day.')

  //           var incrCreditAmount = obj.creditIncr;

  //             let total = parseFloat(obj.credits) + parseFloat(incrCreditAmount);

  //             var saveDoc = await db.collection('userProfiles').doc(obj.email).update({
  //               credits: total.toFixed(2),
  //               creditBalanceDate: currentDate.toString(),
  //               nextIncrDate: inThirtyDays.toString()
  //             }).then(()=>{
  //                 console.log("Bundle Customer - Credits have been updated.");
  //                 return saveDoc;
  //             });
  //         }
  //         else if(currentDateOnly !== incrDateOnly) {
  //           console.log('Today IS NOT Increment Day.')
  //         }

  //       }
          
  //     });
  //   });
  // }

  //---------------------------------------------------------- Bundle Process Testing Ends Here ----------------------------------------------------------

}

//--------------------------------------------------------Supremo Dialog------------------------------------

@Component({
  selector: 'supremo-report',
  templateUrl: 'supremo-report.html',
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]}, //, deps: [MAT_DATE_LOCALE]

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class SupremoDialog {

  lawyerID: any;
  fromDate: any;
  toDate: any;
  selectedCourse: any;

  courses$: any;

  fileName: any;

  // allQueryRegistrations: any;
  // queryRegistrations: any;
  // allQueryCourses: any;
  // queryCourses: any;
  // allQueryCoursesList: any;
  // queryCourse: any;

  title = 'app';

  private gridColumnApi;
  //rowData: any;
  private gridApi;
  public rowData: any;

  selectedDate = {
    fromDate: new Date(),
    toDate: new Date()
  }

  // columnDefs = [
  //     {headerName: 'Make', field: 'make' },
  //     {headerName: 'Model', field: 'model' },
  //     {headerName: 'Price', field: 'price'}
  // ];

  columnDefs = [
    {
      headerName: "IDABOGADO",
      field: "rua",
      width: 150
    },
    {
      headerName: "NOMBRE",
      field: "firstName",
      width: 150
    },
    {
      headerName: "INICIAL",
      field: "initial",
      width: 150
    },
    {
      headerName: "APELLIDOPAT",
      field: "lastNameOne",
      width: 150
    },
    {
      headerName: "APELLIDOMAT",
      field: "lastNameTwo",
      width: 150
    },
    {
      headerName: "IDCURSO",
      field: "courseID",
      width: 150
    },
    {
      headerName: "HORASETICA",
      field: "horasEtica",
      width: 150
    },
    {
      headerName: "HORASNOTARIA",
      field: "horasNotaria",
      width: 150
    },
    {
      headerName: "HORASOTRAS",
      field: "horasGeneral",
      width: 150
    },
  ];

  constructor(
    public firebaseService: FirebaseService,
    public dialog: MatDialog,
    public afAuth: AngularFireAuth,
    private readonly afs: AngularFirestore,
  ) { }

  ngOnInit(): void {
    this.getListCourses();
    this.loadDataSupremo();
    //this.rowData = this.http.get('https://api.myjson.com/bins/15psn9');
  }

  getListCourses() {
    this.firebaseService.getAllCourses().subscribe(data => {
      this.courses$ = data;
      //console.log("this.courses$",this.courses$)
    });
  }

//--------------------------------- Delete Collection Code Starts Here ---------------------------------

deleteReportSupremo(){
  this.removeCollection('tempReportsSupremo');
}

removeCollection(collectionName: string) {
  let collectionPath = db.collection(collectionName);
 return this.deleteCollection(db, collectionPath, 20);
}

private deleteCollection(db, collectionPath, batchSize) {
  var query = collectionPath.orderBy('__name__').limit(batchSize);

  return new Promise((resolve, reject) => {
      this.deleteQueryBatch(db, query, batchSize, resolve, reject);
  });
}

private deleteQueryBatch(db, query, batchSize, resolve, reject) {
  query.get()
      .then((snapshot) => {
          // When there are no documents left, we are done
          if (snapshot.size == 0) {
              return 0;
          }

          // Delete documents in a batch
          var batch = db.batch();
          snapshot.docs.forEach((doc) => {
              batch.delete(doc.ref);
          });

          return batch.commit().then(() => {
              return snapshot.size;
          });
      }).then((numDeleted) => {
          if (numDeleted <= batchSize) {
              resolve();
              return;
          }

          // Recurse on the next process tick, to avoid
          // exploding the stack.
          Promise.resolve().then(() => {
              this.deleteQueryBatch(db, query, batchSize, resolve, reject);
          });
      })
      .catch(reject);
}

//--------------------------------- Delete Collection Code Ends Here ---------------------------------

  onBtnExport(fileName: any) {
    var params = {
      skipHeader: "",
      columnGroups: "",
      skipFooters: "",
      skipGroups: "",
      skipPinnedTop: "",
      skipPinnedBottom: "",
      allColumns: "",
      onlySelected: "",
      suppressQuotes: "",
      fileName: fileName, //Microjuris_RUA
      columnSeparator: ""
    };
    this.gridApi.exportDataAsCsv(params);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  loadDataSupremo() {
    // db.collection("tempReportsRegalia").get().then((data) => {
    //     this.rowData = data;
    // });
    this.afs.collection<any>('tempReportsSupremo').valueChanges().subscribe(data => {
      this.rowData = data;
    });
  }

  filterSupremoReport(lawyerID: any, selectedDate: any, selectedCourse: any){

    const format1 = 'yyyy-MM-dd';
    const myDate1 = selectedDate.fromDate.toString();
    const locale1 = 'en-US';
    const formattedDate1 = formatDate(myDate1, format1, locale1);

    const format2 = 'yyyy-MM-dd';
    const myDate2 = selectedDate.toDate.toString();
    const locale2 = 'en-US';
    const formattedDate2 = formatDate(myDate2, format2, locale2);
    
    var data = {
      lawyerID: lawyerID,
      fromDate: formattedDate1.toString(), //selectedDate.fromDate.toString() //"2018-09-01"
      toDate: formattedDate2.toString(), //selectedDate.toDate.toString() //"2018-09-30"
      selectedCourse: selectedCourse,
    }

    //console.log('lawyerID: ', data.lawyerID);
    //console.log('fromDate: ', data.fromDate);
    //console.log('toDate: ', data.toDate);
    //console.log('selectedCourse: ', data.selectedCourse);
    //console.log('selectedCourse.courseID: ', data.selectedCourse.courseID);

    //Filter By RUA, Dates and Course
    if((data.fromDate !== null || data.fromDate !== "" || data.fromDate !== undefined) && (data.toDate !== null || data.toDate !== "" || data.toDate !== undefined) && (data.lawyerID !== null || data.lawyerID !== "" || data.lawyerID !== undefined) && (data.selectedCourse !== null || data.selectedCourse !== "" || data.selectedCourse !== undefined)){

      //var registrationsRef = db.collection("digitalChalkRegistrations");

      var selectedFromDate = data.fromDate.toString();
      var selectedToDate = data.toDate.toString();
      var courseSelected = data.selectedCourse.toString();

      var allQueryRegistrations;
      var queryRegistrations;
      var allQueryCourses;
      var queryCourses;
      var allQueryCoursesList;
      var queryCourse;

      var registrationsRef = db.collection("digitalChalkRegistrations").where('endDate', '>=', selectedFromDate.toString()).where('endDate', '<=', selectedToDate.toString()).where('courseID', '==', courseSelected.toString());
      registrationsRef.get().then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
              // doc.data() is never undefined for query doc snapshots
              //console.log(doc.id, " => ", doc.data());

              var dataRegistrations = {
                registrationID: doc.data().registrationID.toString(),
                courseID: doc.data().courseID.toString(),
              };

              var coursesFormstackRef = db.collection("coursesFormstack").where('rua', '==', this.lawyerID.toString()).where('registrationID', '==', dataRegistrations.registrationID.toString());
              coursesFormstackRef.get().then(function(querySnapshot) {
                  querySnapshot.forEach(function(doc2) {
                      // doc.data() is never undefined for query doc snapshots
                      //console.log(doc.id, " => ", doc2.data());

                      var coursesListRef = db.collection("masterListCourses").where('courseID', '==', dataRegistrations.courseID);
                      coursesListRef.get().then(function(querySnapshot) {
                          querySnapshot.forEach(async function(doc3) {
                              // doc.data() is never undefined for query doc snapshots
                              //console.log(doc.id, " => ", doc3.data());

                              var coursesListData = doc3.data();

                              var dataCourses = {
                                rua: doc2.data().rua.toString(),
                                firstName: doc2.data().firstName.toString(),
                                initial: doc2.data().initial.toString(),
                                lastNameOne: doc2.data().lastNameOne.toString(),
                                lastNameTwo: doc2.data().lastNameTwo.toString(),
                                courseID: doc2.data().courseID.toString(),
                                horasGeneral: coursesListData.horasGeneral.toString(),
                                horasEtica: coursesListData.horasEtica.toString(),
                                horasNotaria: coursesListData.horasNotaria.toString()
                              };

                              var tempRef = await db.collection("tempReportsSupremo").add(dataCourses).then(()=>{
                                console.log("Document successfully updated!");
                                return [tempRef,this.loadDataRegalia()];
                              }).catch(function(error) {
                                // The document probably doesn't exist.
                                console.error("Error updating document: ", error);
                              });

                          });
                      })
                      .catch(function(error) {
                          console.log("Error getting documents: ", error);
                      });
                  });
              })
              .catch(function(error) {
                  console.log("Error getting documents: ", error);
              });
          });
      })
      .catch(function(error) {
          console.log("Error getting documents: ", error);
      });
    }
  }

  // rowData = [
  //     { make: 'Toyota', model: 'Celica', price: 35000 },
  //     { make: 'Ford', model: 'Mondeo', price: 32000 },
  //     { make: 'Porsche', model: 'Boxter', price: 72000 }
  // ];  

  // rowData = [
  //   { make: 'Toyota', model: 'Celica', price: 35000 },
  //   { make: 'Ford', model: 'Mondeo', price: 32000 },
  //   { make: 'Porsche', model: 'Boxter', price: 72000 }
  // ];

} 

//--------------------------------------------------------Regalia Dialog------------------------------------

@Component({
  selector: 'regalia-report',
  templateUrl: 'regalia-report.html',
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]}, //, deps: [MAT_DATE_LOCALE]

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class RegaliaDialog {

  lawyerID: any;
  fromDate: any;
  toDate: any;
  selectedCourse: any;

  fileName: any;

  // allQueryRegistrations: any;
  // queryRegistrations: any;
  // allQueryCourses: any;
  // queryCourses: any;
  // allQueryCoursesList: any;
  // queryCourse: any;

  courses$: any;

  title = 'app';

  private gridColumnApi;
  //rowData: any;
  private gridApi;
  public rowData: any;

  selectedDate = {
    fromDate: new Date(),
    toDate: new Date()
  }

  constructor(
    public firebaseService: FirebaseService,
    public dialog: MatDialog,
    public afAuth: AngularFireAuth,
    private readonly afs: AngularFirestore,
  ) { }

  // columnDefs = [
  //     {headerName: 'Make', field: 'make' },
  //     {headerName: 'Model', field: 'model' },
  //     {headerName: 'Price', field: 'price'}
  // ];

    columnDefs = [
    {
      headerName: "NOMBRE",
      field: "firstName",
      width: 150
    },
    {
      headerName: "INICIAL",
      field: "initial",
      width: 150
    },
    {
      headerName: "APELLIDOPAT",
      field: "lastNameOne",
      width: 150
    },
    {
      headerName: "APELLIDOMAT",
      field: "lastNameTwo",
      width: 150
    },
    {
      headerName: "CURSO",
      field: "courseTitle",
      width: 150
    },
    {
      headerName: "FECHA",
      field: "registrationDate",
      width: 150
    },
    {
      headerName: "PROFESOR",
      field: "professorName",
      width: 150
    },
    {
      headerName: "COSTO",
      field: "CostPerCreditFIFO",
      width: 150
    },
  ];

  ngOnInit(): void {
    this.getListCourses();
    this.loadDataRegalia();
    //this.rowData = this.http.get('https://api.myjson.com/bins/15psn9');
    // db.collection("tempReportsRegalia").get().then(function(querySnapshot) {
    //   querySnapshot.forEach(function(doc) {
    //       // doc.data() is never undefined for query doc snapshots
    //       console.log(doc.id, " => ", doc.data());
    //       var allData = doc.data();
    //       this.rowData = allData;
    // });
    // });
  }

  getListCourses() {
    this.firebaseService.getAllCourses().subscribe(data => {
      this.courses$ = data;
      //console.log("this.courses$",this.courses$)
    });
  }

  // onBtnExport() {
  //   var params = getParams();
  //   if (params.suppressQuotes || params.columnSeparator) {
  //     alert(
  //       'NOTE: you are downloading a file with non-standard quotes or separators - it may not render correctly in Excel.'
  //     );
  //   }
  //   this.gridApi.exportDataAsCsv(params);
  // }

  onBtnExport(fileName: any) {
    //var fileName: any;
    var params = {
      skipHeader: "",
      columnGroups: "",
      skipFooters: "",
      skipGroups: "",
      skipPinnedTop: "",
      skipPinnedBottom: "",
      allColumns: "",
      onlySelected: "",
      suppressQuotes: "",
      fileName: fileName, //Microjuris_RUA
      columnSeparator: ""
    };
    this.gridApi.exportDataAsCsv(params);
  }

  //--------------------------------- Delete Collection Code Starts Here ---------------------------------

  deleteReportRegalia(){
    this.removeCollection('tempReportsRegalia');
  }

  removeCollection(collectionName: string) {
    let collectionPath = db.collection(collectionName);
   return this.deleteCollection(db, collectionPath, 20);
  }

  private deleteCollection(db, collectionPath, batchSize) {
    var query = collectionPath.orderBy('__name__').limit(batchSize);

    return new Promise((resolve, reject) => {
        this.deleteQueryBatch(db, query, batchSize, resolve, reject);
    });
  }

  private deleteQueryBatch(db, query, batchSize, resolve, reject) {
    query.get()
        .then((snapshot) => {
            // When there are no documents left, we are done
            if (snapshot.size == 0) {
                return 0;
            }

            // Delete documents in a batch
            var batch = db.batch();
            snapshot.docs.forEach((doc) => {
                batch.delete(doc.ref);
            });

            return batch.commit().then(() => {
                return snapshot.size;
            });
        }).then((numDeleted) => {
            if (numDeleted <= batchSize) {
                resolve();
                return;
            }

            // Recurse on the next process tick, to avoid
            // exploding the stack.
            Promise.resolve().then(() => {
                this.deleteQueryBatch(db, query, batchSize, resolve, reject);
            });
        })
        .catch(reject);
  }

//--------------------------------- Delete Collection Code Ends Here ---------------------------------

  // onBtnUpdate() {
  //   document.querySelector('#csvResult').value = this.gridApi.getDataAsCsv(
  //     getParams()
  //   );
  // }

  // onGridReady(params) {
  //   this.gridApi = params.api;
  //   this.gridColumnApi = params.columnApi;
  // }

  // onBtExport() {
  //   var params = {
  //     skipHeader: "",
  //     columnGroups: "",
  //     skipFooters: "",
  //     skipGroups: "",
  //     skipPinnedTop: "",
  //     skipPinnedBottom: "",
  //     allColumns: "",
  //     onlySelected: "",
  //     suppressQuotes: "",
  //     fileName: "Microjuris_RUA",
  //     columnSeparator: ""
  //   };
  //   this.gridApi.exportDataAsCsv(params);
  // }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  regalia$: any;

  loadDataRegalia() {
    // db.collection("tempReportsRegalia").get().then((data) => {
    //     this.rowData = data;
    // });
    this.afs.collection<any>('tempReportsRegalia').valueChanges().subscribe(data => {
      this.rowData = data;
    })
  }

  filterRegaliaReport(selectedDate: any, selectedCourse: any) { //, fromDate: any, toDate: any //selectedDate: any, selectedCourse: any

    //let now = moment().format('YYYY-MM-DD');

    const format1 = 'yyyy-MM-dd';
    const myDate1 = selectedDate.fromDate.toString();
    const locale1 = 'en-US';
    const formattedDate1 = formatDate(myDate1, format1, locale1);

    const format2 = 'yyyy-MM-dd';
    const myDate2 = selectedDate.toDate.toString();
    const locale2 = 'en-US';
    const formattedDate2 = formatDate(myDate2, format2, locale2);
    
    var data = {
      fromDate: formattedDate1.toString(), //selectedDate.fromDate.toString() //"2018-09-01"
      toDate: formattedDate2.toString(), //selectedDate.toDate.toString() //"2018-09-30"
      selectedCourse: selectedCourse,
    }

    //console.log('fromDate: ', data.fromDate);
    //console.log('toDate: ', data.toDate);
    //console.log('selectedCourse: ', data.selectedCourse);
    //console.log('selectedCourse.courseID: ', data.selectedCourse.courseID);

    //Filter By Dates and By Course
  if((data.fromDate !== null || data.fromDate !== "" || data.fromDate !== undefined) && (data.toDate !== null || data.toDate !== "" || data.toDate !== undefined) && (data.selectedCourse.courseID !== null || data.selectedCourse.courseID !== "" || data.selectedCourse.courseID !== undefined)){
    var selectedFromDate = data.fromDate; //selectedDate.fromDate.toString()
    var selectedToDate = data.toDate; //selectedDate.toDate.toString()
    var courseSelected = data.selectedCourse.courseID.toString();

    //console.log('selectedFromDate-2: ', data.fromDate);
    //console.log('selectedToDate-2: ', data.toDate);
    //console.log('courseSelected-2: ', courseSelected);
    //console.log('selectedCourse.courseID-2: ', data.selectedCourse.courseID);

    //CHANGE BACK getDocuments3('') TO digitalChalkRegistrations
    //digitalChalkRegistrations <<<<<<<<<<<<<<<<<<< CHANGE BACK TO THIS!!!!

    //var registrationsRef = db.collection("digitalChalkRegistrations");

    var testQuery;

    var allQueryRegistrations;
    var queryRegistrations;
    var allQueryCourses;
    var queryCourses;
    var allQueryCoursesList;
    var queryCourse;

    // db.collection("cities").where("capital", "==", true)
    // .get()
    // .then(function(querySnapshot) {
    //     querySnapshot.forEach(function(doc) {
    //         // doc.data() is never undefined for query doc snapshots
    //         console.log(doc.id, " => ", doc.data());
    //     });
    // })
    // .catch(function(error) {
    //     console.log("Error getting documents: ", error);
    // });

    var registrationsRef = db.collection('digitalChalkRegistrations').where('endDate', '>=', selectedFromDate.toString()).where('endDate', '<=', selectedToDate.toString()).where('courseID', '==', courseSelected.toString());
    registrationsRef.get().then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
            //console.log(doc.id, ' => ', doc.data());

            var allQueryRegistrations;
            var queryRegistrations;
            var allQueryCourses;
            var queryCourses;
            var allQueryCoursesList;
            var queryCourse;

            var dataRegistrations = {
              registrationID: doc.data().registrationID.toString(),
              courseTitle:  doc.data().courseTitle.toString(),
              digitalChalkUserID:  doc.data().digitalChalkUserID.toString(),
              courseID:  doc.data().courseID.toString(),
              registrationStatus:  doc.data().registrationStatus.toString(),
              courseGrade:  doc.data().courseGrade.toString(),
              createdDate:  doc.data().createdDate.toString(),
              beginDate:  doc.data().beginDate.toString(),
              lastActiveDate:  doc.data().lastActiveDate.toString(),
              endDate:  doc.data().endDate.toString(),
              //professorName: allfilteredRegistrations.professorName.toString() ? allfilteredRegistrations.professorName.toString() : null,
              // horasGeneral: null,
              // horasEtica: null,
              // horasNotaria: null
            };
            //console.log('ID: ', allRegistrations.registrationID)
            //console.log(`Data: ${JSON.stringify(dataRegistrations)}`);
    
            // db.collection("tempReportsRegalia").doc(doc.data().registrationID).set(dataRegistrations).then(function() {
            //   console.log("Document successfully written!");
            // });

            db.collection("tempReportsRegalia").add(dataRegistrations).then((docData)=>{

              var coursesListRef = db.collection('masterListCourses').where('courseID', '==', courseSelected.toString());
              coursesListRef.get().then(function (querySnapshot) {
                  querySnapshot.forEach(async function (doc) {
                      //console.log(doc.id, ' => ', doc.data());

                      var coursesListRef = db.collection('coursesFormstack').where('registrationID', '==', dataRegistrations.registrationID.toString());
                      coursesListRef.get().then(function (querySnapshot) {
                          querySnapshot.forEach(async function (doc2) {

                            var coursesFormstackData = doc2.data();
  
                      var dataCourses = {
                        //rua: allRegisteredCourses.rua.toString() ? allRegisteredCourses.rua.toString() : null,
                        firstName: coursesFormstackData.firstName.toString(),
                        initial: coursesFormstackData.initial.toString(),
                        lastNameOne: coursesFormstackData.lastNameOne.toString(),
                        lastNameTwo: coursesFormstackData.lastNameTwo.toString(),
                        registrationDate: coursesFormstackData.registrationDate.toString(),
                        CostPerCreditFIFO: coursesFormstackData.CostPerCreditFIFO.toString(),
                        professorName: doc.data().professorName.toString()
                      };
              
                      //Update Documents******
                      var tempRef = await db.collection("tempReportsRegalia").doc(docData.id).update(dataCourses).then(()=>{
                        console.log("Document successfully updated!");
                        return [tempRef,this.loadDataRegalia()];
                      }).catch(function(error) {
                          // The document probably doesn't exist.
                          console.error("Error updating document: ", error);
                      });

                  });
              });              

            });

        });
    });
  });
  });
  }
  }

  // rowData = [
  //     { make: 'Toyota', model: 'Celica', price: 35000 },
  //     { make: 'Ford', model: 'Mondeo', price: 32000 },
  //     { make: 'Porsche', model: 'Boxter', price: 72000 }
  // ];

  // rowData = [
  //   { make: 'Toyota', model: 'Celica', price: 35000 },
  //   { make: 'Ford', model: 'Mondeo', price: 32000 },
  //   { make: 'Porsche', model: 'Boxter', price: 72000 }
  // ];

} 
