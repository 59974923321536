<h2 mat-dialog-title>Add New Course Request</h2>
<mat-dialog-content class="mat-typography">
<div>
    <mat-form-field appearance="fill">
        <mat-label>Formstack ID</mat-label>
        <input matInput name="id" [(ngModel)]="this.firebaseService.newCourseRequest.id">
    </mat-form-field>
    <mat-form-field appearance="fill" style="margin-left: 7px;">
        <mat-label>First Name</mat-label>
        <input matInput name="firstName" [(ngModel)]="this.firebaseService.newCourseRequest.firstName">
    </mat-form-field>
</div>    
<div>
    <mat-form-field appearance="fill">
        <mat-label>Initial</mat-label>
        <input matInput name="initial" [(ngModel)]="this.firebaseService.newCourseRequest.initial">
    </mat-form-field>      
    <mat-form-field appearance="fill" style="margin-left: 7px;">
        <mat-label>Paternal Last Name</mat-label>
        <input matInput name="lastNameOne" [(ngModel)]="this.firebaseService.newCourseRequest.lastNameOne">
    </mat-form-field>  
</div>
<div>
    <mat-form-field appearance="fill">
        <mat-label>Maternal Last Name</mat-label>
        <input matInput name="lastNameTwo" [(ngModel)]="this.firebaseService.newCourseRequest.lastNameTwo">
    </mat-form-field>      
    <mat-form-field appearance="fill" style="margin-left: 7px;">
        <mat-label>Email</mat-label>
        <input matInput name="email" [(ngModel)]="this.firebaseService.newCourseRequest.email">
    </mat-form-field>
</div>
<div>
    <mat-form-field appearance="fill">
        <mat-label>Mobile</mat-label>
        <input matInput name="mobile" [(ngModel)]="this.firebaseService.newCourseRequest.mobile">
    </mat-form-field>
    <mat-form-field appearance="fill" style="margin-left: 7px;">
        <mat-label>RUA</mat-label>
        <input matInput name="rua" [(ngModel)]="this.firebaseService.newCourseRequest.rua">
    </mat-form-field>
</div>
<div>    
    <mat-form-field appearance="fill">
        <mat-label>Course Credits</mat-label>
        <input matInput name="credits" [(ngModel)]="this.firebaseService.newCourseRequest.credits">
    </mat-form-field>
    <mat-form-field appearance="fill" style="margin-left: 7px;">
        <mat-label>Course Ref</mat-label>
        <input matInput name="courseRef" [(ngModel)]="this.firebaseService.newCourseRequest.courseRef">
    </mat-form-field>    
</div>
<div>    
    <mat-form-field appearance="fill">
        <mat-label>Course Name</mat-label>
        <input matInput name="courseName" [(ngModel)]="this.firebaseService.newCourseRequest.courseName">
    </mat-form-field>
    <mat-form-field appearance="fill" style="margin-left: 7px;">
        <mat-label>Course ID</mat-label>
        <input matInput name="courseID" [(ngModel)]="this.firebaseService.newCourseRequest.courseID">
    </mat-form-field>    
</div>
<div>    
    <mat-form-field appearance="fill">
        <mat-label>FIFO Cost Per Credit</mat-label>
        <input matInput name="FIFOCostPerCredit" [(ngModel)]="this.firebaseService.newCourseRequest.FIFOCostPerCredit">
    </mat-form-field> 
</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial (click)="onSaveAddForm()">Save</button>
</mat-dialog-actions>