<form class="example-form">
    <mat-form-field class="example-full-width">
        <mat-label>Search</mat-label>
        <input matInput (keyup)="applyFilter($event.target.value)">
    </mat-form-field>
</form>

<div class="mat-elevation-z8">

        <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="options">
                <th mat-header-cell *matHeaderCellDef>  </th>
                <td mat-cell *matCellDef="let credit">
                    <!-- <button mat-icon-button color="primary">
                        <mat-icon>settings</mat-icon>
                    </button> -->
                    <button mat-icon-button [matMenuTriggerFor]="menu" color="primary">
                            <mat-icon>settings</mat-icon>
                          </button>
                          <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)="openEditDialog(credit)">
                              <mat-icon>edit</mat-icon>
                              <span>Edit</span>
                            </button>
                            <button mat-menu-item *ngIf="isCanDelete" (click)="onDelete(credit.id)"> <!--disabled-->
                              <mat-icon>delete</mat-icon>
                              <span>Delete</span>
                            </button>
                            <!-- <button mat-menu-item>
                              <mat-icon>notifications_off</mat-icon>
                              <span>Disable alerts</span>
                            </button> -->
                          </mat-menu>                    
            </ng-container>            

            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Transaction ID </th>
                <td mat-cell *matCellDef="let credit"> {{credit.id}} </td>
            </ng-container>            
      
          <ng-container matColumnDef="firstName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> First Name </th>
            <td mat-cell *matCellDef="let credit"> {{credit.firstName}} </td>
          </ng-container>

          <ng-container matColumnDef="lastNameOne">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Paternal Last Name </th>
            <td mat-cell *matCellDef="let credit"> {{credit.lastNameOne}} </td>
          </ng-container>
      
          <ng-container matColumnDef="lastNameTwo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Maternal Last Name </th>
            <td mat-cell *matCellDef="let credit"> {{credit.lastNameTwo}} </td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
            <td mat-cell *matCellDef="let credit"> {{credit.email}} </td>
        </ng-container>          

            <ng-container matColumnDef="rua">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> RUA </th>
                <td mat-cell *matCellDef="let credit"> {{credit.rua}} </td>
            </ng-container>        

            <ng-container matColumnDef="mobile">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Phone </th>
                <td mat-cell *matCellDef="let credit"> {{credit.mobile}} </td>
            </ng-container>

            <ng-container matColumnDef="total">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Total </th>
                <td mat-cell *matCellDef="let credit"> {{credit.total}} </td>
            </ng-container>                

            <ng-container matColumnDef="netTotal">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Net Total </th>
                <td mat-cell *matCellDef="let credit"> {{credit.netTotal}} </td>
            </ng-container>         
            
            <ng-container matColumnDef="ivuAmount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> IVU Amount </th>
                <td mat-cell *matCellDef="let credit"> {{credit.ivuAmount}} </td>
            </ng-container> 

            <ng-container matColumnDef="credits">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Credit Amount </th>
                <td mat-cell *matCellDef="let credit"> {{credit.credits}} </td>
            </ng-container> 

            <ng-container matColumnDef="typeOfTransaction">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Transaction Type </th>
                <td mat-cell *matCellDef="let credit"> {{credit.typeOfTransaction}} </td>
            </ng-container> 

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Transaction Status </th>
                <td mat-cell *matCellDef="let credit"> {{credit.status}} </td>
            </ng-container>            

            <ng-container matColumnDef="emailStatus">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Email Status </th>
                <td mat-cell *matCellDef="let credit"> {{credit.emailStatus}} </td>
            </ng-container> 
      
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      
        <mat-paginator [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons></mat-paginator>
      </div>
