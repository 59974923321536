<h2 mat-dialog-title>Add New User</h2>
<mat-dialog-content class="mat-typography">
<div>
    <mat-form-field appearance="fill">
        <mat-label>Email</mat-label>
        <input matInput name="email" [(ngModel)]="this.firebaseService.newUser.email">
    </mat-form-field>
    <mat-form-field appearance="fill" style="margin-left: 7px;">
        <mat-label>Password</mat-label>
        <input matInput name="password" [(ngModel)]="this.firebaseService.newUser.password">
    </mat-form-field>
</div>    
<div>    
    <mat-form-field appearance="fill">
        <mat-label>First Name</mat-label>
        <input matInput name="firstName" [(ngModel)]="this.firebaseService.newUser.firstName">
    </mat-form-field>
    <mat-form-field appearance="fill" style="margin-left: 7px;">
        <mat-label>Initial</mat-label>
        <input matInput name="initial" [(ngModel)]="this.firebaseService.newUser.initial">
    </mat-form-field>    
</div>
<div>
    <mat-form-field appearance="fill">
        <mat-label>Paternal Last Name</mat-label>
        <input matInput name="lastNameOne" [(ngModel)]="this.firebaseService.newUser.lastNameOne">
    </mat-form-field>
    <mat-form-field appearance="fill" style="margin-left: 7px;">
        <mat-label>Maternal Last Name</mat-label>
        <input matInput name="lastNameTwo" [(ngModel)]="this.firebaseService.newUser.lastNameTwo">
    </mat-form-field>
</div>
<div>
    <mat-form-field appearance="fill">
        <mat-label>Mobile</mat-label>
        <input matInput name="mobile" [(ngModel)]="this.firebaseService.newUser.mobile">
    </mat-form-field>    
    <mat-form-field appearance="fill" style="margin-left: 7px;">
        <mat-label>Display Name</mat-label>
        <input matInput name="displayName" [(ngModel)]="this.firebaseService.newUser.displayName">
    </mat-form-field>  
</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial (click)="onSaveAddForm()">Save</button>
</mat-dialog-actions>