import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {Sort} from '@angular/material/sort';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatSort} from '@angular/material/sort';
import {AngularFirestore} from '@angular/fire/firestore';
import {UsersI} from '../users/users';
import {FirebaseService} from '../services/firebase.service';
import {AngularFireAuth} from '@angular/fire/auth';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  displayedColumns: string[] = ['options', 'firstName', 'initial', 'lastNameOne', 'lastNameTwo', 'email', 'mobile'];
  dataSource = new MatTableDataSource();

  //@ViewChild(MatSort) sort: MatSort;

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  public isCanDelete: Boolean = false;

  public userData: any;

  constructor(
    private afs: AngularFirestore, 
    public dialog: MatDialog,
    public firebaseService: FirebaseService,
    public afAuth: AngularFireAuth,
  ) {
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.userData = user;
        //console.log("this.userData", this.userData)
        this.afs.collection('userProfiles').doc(`${user.email}`).valueChanges().subscribe(data =>{
          //console.log('data: ', data)
          //console.log('data.deleteAdmin:', data["deleteAdmin"])
          this.isCanDelete = data["deleteAdmin"];
        });
      } else {
        console.log('Insufficient Permissions')
      }
    });
   }

  ngOnInit(): void {
    this.firebaseService.getAllUsers().subscribe(res => {
      this.dataSource.data = res;
      this.dataSource.paginator = this.paginator;
    });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    // this.afs.collection<any>('userProfiles', ref => ref.where('profileType', '==', 'Employee')).valueChanges().subscribe(data => {
    //   this.dataSource = new MatTableDataSource(data); 
    //   this.dataSource.paginator = this.paginator;
    //   this.dataSource.sort = this.sort;
    // })
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); 
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  openEditDialog(profile) {
    const dialogRef = this.dialog.open(UserEditDialog);
    this.firebaseService.selectedUser = profile;

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  openNewDialog() {
    const dialogRef = this.dialog.open(UserNewDialog);
    //this.firebaseService.selected = customer;

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  onDelete(id:string){
    this.firebaseService.deleteUsers(id);
  }

}

//--------------------------------------------------------Edit User Dialog--------------------------------------------------------

@Component({
  selector: 'users-edit-dialog',
  templateUrl: 'users-edit-dialog.html',
})
export class UserEditDialog {

  savedValues: any;

  constructor(
    public firebaseService: FirebaseService,
  ) { }

  onEdit(profile){
    this.firebaseService.selectedUser = profile;
    //console.log('Edit', customer)
  }

  onSaveEditForm(){
    this.savedValues = this.firebaseService.selectedUser;
    this.firebaseService.editUsers(this.savedValues);
    //console.log('Save')
  }

}

//--------------------------------------------------------Add New User Dialog--------------------------------------------------------

@Component({
  selector: 'users-add-new-dialog',
  templateUrl: 'users-add-new-dialog.html',
})
export class UserNewDialog {

  savedValues: any;
  newValues: any;

  constructor(
    public firebaseService: FirebaseService,
    public dialog: MatDialog,
  ) { }

  onSaveAddForm(){
    //this.savedValues = this.firebaseService.selected;
    //if(this.firebaseService.newCustomer.id == null){
      //new customer
      let newUserData = {
        Admin: true,
        deleteAdmin: false,
        displayName: this.firebaseService.newUser.displayName.toLowerCase().trim(),
        email: this.firebaseService.newUser.email.toLowerCase().trim(),
        firstName: this.firebaseService.newUser.firstName.trim(),
        initial: this.firebaseService.newUser.initial.trim(),
        lastNameOne: this.firebaseService.newUser.lastNameOne.trim(),
        lastNameTwo: this.firebaseService.newUser.lastNameTwo.trim(),
        mobile: this.firebaseService.newUser.mobile.trim(),
        password: this.firebaseService.newUser.password.trim(),
        profileType: 'Employee',
        role: 'Admin',
        superAdmin: false,
        uid: this.firebaseService.newUser.email.toLowerCase().trim(),
        id: this.firebaseService.newUser.email.toLowerCase().trim(),
      }
      //('New', newUserData)
      this.newValues = newUserData;
      this.firebaseService.addUsers(this.newValues).then(async()=>{
        //await window.location.reload();
        await this.openAddConfirmDialog();
      });
    //}
    // else{
    //   this.firebaseService.editCustomer(this.savedValues);
    //   console.log('Save')
    // }
  }

  openAddConfirmDialog() {
    const dialogRef = this.dialog.open(UserAddConfirmDialog);
    //this.firebaseService.selected = customer;

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      this.firebaseService.newUser.email = "";
      this.firebaseService.newUser.password = "";
      this.firebaseService.newUser.firstName = "";
      this.firebaseService.newUser.initial = "";
      this.firebaseService.newUser.lastNameOne = "";
      this.firebaseService.newUser.lastNameTwo = "";
      this.firebaseService.newUser.mobile = "";
      this.firebaseService.newUser.displayName = "";
      //window.location.reload();
    });
  }

}

//--------------------------------------------------------Add User Confirmation Dialog------------------------------------

@Component({
  selector: 'users-add-confirm-dialog',
  templateUrl: 'users-add-confirm-dialog.html',
})
export class UserAddConfirmDialog {

  constructor(
    public firebaseService: FirebaseService,
  ) { }


}
