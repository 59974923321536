import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {Sort} from '@angular/material/sort';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatSort} from '@angular/material/sort';
import {AngularFirestore} from '@angular/fire/firestore';
import {FirebaseService} from '../services/firebase.service';
import { AuthService } from '../services/auth.service';
import {CreditsI} from '../credits/credits';
import {AngularFireAuth} from '@angular/fire/auth';

@Component({
  selector: 'app-credits',
  templateUrl: './credits.component.html',
  styleUrls: ['./credits.component.scss']
})
export class CreditsComponent implements OnInit {

  displayedColumns: string[] = ['options', 'id', 'firstName', 'lastNameOne', 'lastNameTwo', 'email', 'rua', 'mobile', 'total', 'netTotal', 'ivuAmount', 'credits', 'typeOfTransaction', 'status', 'emailStatus'];
  dataSource = new MatTableDataSource();

  @ViewChild(MatSort) sort: MatSort;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  public isCanDelete: Boolean = false;

  public userData: any;

  constructor(
    private afs: AngularFirestore, 
    public dialog: MatDialog,
    public firebaseService: FirebaseService,
    public authService: AuthService,
    public afAuth: AngularFireAuth,
  ) {
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.userData = user;
        //console.log("this.userData", this.userData)
        this.afs.collection('userProfiles').doc(`${user.email}`).valueChanges().subscribe(data =>{
          //console.log('data: ', data)
          //console.log('data.deleteAdmin:', data["deleteAdmin"])
          this.isCanDelete = data["deleteAdmin"];
        });
      } else {
        console.log('Insufficient Permissions')
      }
    });
   }

  ngOnInit(): void {
    this.firebaseService.getAllCredits().subscribe(res => {
      this.dataSource.data = res;
      this.dataSource.paginator = this.paginator;
    })
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    // this.afs.collection<any>('creditsFormstack').valueChanges().subscribe(data => {
    //   this.dataSource = new MatTableDataSource(data); 
    //   this.dataSource.paginator = this.paginator;
    //   this.dataSource.sort = this.sort;
    // })
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); 
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  openEditDialog(credit) {
    const dialogRef = this.dialog.open(CreditsEditDialog);
    this.firebaseService.selectedCredit = credit;

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  onDelete(id:string){
    this.firebaseService.deleteCredit(id);
    //console.log("Deleted: ", id)
  }

}

@Component({
  selector: 'credits-edit-dialog',
  templateUrl: 'credits-edit-dialog.html',
})
export class CreditsEditDialog {

  savedCreditValues: any;

  constructor(
    public firebaseService: FirebaseService,
  ) { }

  onEdit(credit){
    this.firebaseService.selectedCredit = credit;
  }

  onSaveEditForm(){
    this.savedCreditValues = this.firebaseService.selectedCredit;
    this.firebaseService.editCredits(this.savedCreditValues);
  }

}
