<body>
<div class="main-div">
<div class="example-card">
    <div style="text-align: center;vertical-align: middle;"><h1><mat-icon color="primary">list_alt</mat-icon> &nbsp; Reports</h1></div>
    <!-- <div style="text-align: center;line-height: 3px;"><h2>Generate Reports</h2></div> -->
    <p>
        <mat-list>
            <div mat-subheader>Report</div>
            <mat-list-item *ngFor="let one of reportsOne" (click)="openSupremoDialog()">
                <mat-icon mat-list-icon>insert_drive_file</mat-icon>
                <div mat-line>{{one.name}}</div>
            </mat-list-item>
            <mat-divider></mat-divider>
            <div mat-subheader>Report</div>
            <mat-list-item *ngFor="let two of reportsTwo" (click)="openRegaliaDialog()">
                <mat-icon mat-list-icon>insert_drive_file</mat-icon>
                <div mat-line>{{two.name}}</div>
            </mat-list-item>
        </mat-list>
    </p>
</div>    
</div>
<!-- <p><button mat-flat-button color="primary" (click)="bundleTest()">Test Bundle</button></p> -->
</body>