<h2 mat-dialog-title>Edit Course</h2> 
<mat-dialog-content class="mat-typography">
<div>
    <mat-form-field appearance="fill">
        <mat-label>Course ID</mat-label>
        <input matInput name="courseID" [(ngModel)]="this.firebaseService.selectedCourse.courseID">
    </mat-form-field>
    <mat-form-field appearance="fill" style="margin-left: 7px;">
        <mat-label>Course Name</mat-label>
        <input matInput name="courseName" [(ngModel)]="this.firebaseService.selectedCourse.courseName">
    </mat-form-field>
</div>    
<div>
    <mat-form-field appearance="fill">
        <mat-label>Course Credit</mat-label>
        <input matInput name="courseCredit" [(ngModel)]="this.firebaseService.selectedCourse.courseCredit">
    </mat-form-field>      
    <mat-form-field appearance="fill" style="margin-left: 7px;">
        <mat-label>Course Ref</mat-label>
        <input matInput name="courseRef" [(ngModel)]="this.firebaseService.selectedCourse.courseRef">
    </mat-form-field>  
</div>
<div>
    <mat-form-field appearance="fill">
        <mat-label>Start Date</mat-label>
        <input matInput name="startDate" [(ngModel)]="this.firebaseService.selectedCourse.startDate">
    </mat-form-field>
    <mat-form-field appearance="fill" style="margin-left: 7px;">
        <mat-label>End Date</mat-label>
        <input matInput name="endDate" [(ngModel)]="this.firebaseService.selectedCourse.endDate">
    </mat-form-field>
</div>
<div>    
    <mat-form-field appearance="fill">
        <mat-label>Course Status</mat-label>
        <mat-select name="courseStatus" [(ngModel)]="this.firebaseService.selectedCourse.courseStatus">
            <mat-option value="Active">Active</mat-option>
            <mat-option value="Inactive">Inactive</mat-option>
        </mat-select>
    </mat-form-field>     
    <mat-form-field appearance="fill" style="margin-left: 7px;">
        <mat-label>Professor Name</mat-label>
        <input matInput name="professorName" [(ngModel)]="this.firebaseService.selectedCourse.professorName">
    </mat-form-field>    
</div>
<div>
    <mat-form-field appearance="fill">
        <mat-label>Horas General</mat-label>
        <input matInput name="horasGeneral" [(ngModel)]="this.firebaseService.selectedCourse.horasGeneral">
    </mat-form-field> 
    <mat-form-field appearance="fill" style="margin-left: 7px;">
        <mat-label>Horas Etica</mat-label>
        <input matInput name="horasEtica" [(ngModel)]="this.firebaseService.selectedCourse.horasEtica">
    </mat-form-field>     
</div>
<div>
    <mat-form-field appearance="fill">
        <mat-label>Horas Notaria</mat-label>
        <input matInput name="horasNotaria" [(ngModel)]="this.firebaseService.selectedCourse.horasNotaria">
    </mat-form-field> 
    <mat-form-field appearance="fill" style="margin-left: 7px;">
        <mat-label>Course Activation Type</mat-label>
        <mat-select name="activationType" [(ngModel)]="this.firebaseService.selectedCourse.activationType">
            <mat-option value="Credits">Credits</mat-option>
            <mat-option value="Individual">Individual</mat-option>
        </mat-select>
    </mat-form-field>   
</div>
<div>
    <mat-form-field appearance="fill">
        <mat-label>Topic</mat-label>
        <textarea matInput name="topic" [(ngModel)]="this.firebaseService.selectedCourse.topic"></textarea>
    </mat-form-field>     
</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial (click)="onSaveEditForm()">Save</button>
</mat-dialog-actions>