import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '../services/auth.service';

import {AngularFireAuth} from '@angular/fire/auth';

import { first, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService,
    public afAuth: AngularFireAuth,
  ){}

  // canActivate(
  //   route: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot) {
  //     const currentUser = this.authService.currentUserValue;
  //     if(currentUser) {
  //       if(route.data.roles && route.data.roles.indexOf(currentUser.role) === -1)
  //     }
  //   return true;
  // }

  isLoggedIn() {
    return this.afAuth.authState.pipe(first())
  }

  canActivate(
    next: ActivatedRouteSnapshot, state: RouterStateSnapshot) { //: Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    // return true;

    this.isLoggedIn().pipe(
      tap(user => {
        if (!user) {
          // do something
          console.log("user", user)
          this.router.navigate(['/login']);
          return false;
        }
      })
    )
    .subscribe()

    return true;
  }
  
}
