<h2 mat-dialog-title>Generate Report: Regalia</h2>
<mat-dialog-content class="mat-typography">
<div style="text-align: center;">    
<div>
    <mat-form-field style="margin-left: 7px;">
        <mat-label>From Date</mat-label>
        <input matInput [matDatepicker]="pickerOne" [(ngModel)]='selectedDate.fromDate' name="fromDate" required> <!--required-->
        <mat-datepicker-toggle matSuffix [for]="pickerOne"></mat-datepicker-toggle>
        <mat-datepicker #pickerOne></mat-datepicker>
    </mat-form-field>
    
    <mat-form-field style="margin-left: 7px;">
        <mat-label>To Date</mat-label>
        <input matInput [matDatepicker]="pickerTwo" [(ngModel)]='selectedDate.toDate' name='toDate' required> <!--required-->
        <mat-datepicker-toggle matSuffix [for]="pickerTwo"></mat-datepicker-toggle>
        <mat-datepicker #pickerTwo></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="fill" style="margin-left: 7px;">
        <mat-label>Course</mat-label>
        <mat-select name="selectedCourse" [(ngModel)]="selectedCourse" required>
            <mat-option *ngFor="let item of courses$" [value]="item">{{item.title}}</mat-option>
        </mat-select>
    </mat-form-field>    
</div>    
<!-- <div>
    <mat-form-field appearance="fill" style="margin-left: 7px;">
        <mat-label>Course</mat-label>
        <mat-select name="selectedCourse" [(ngModel)]="selectedCourse" required>
            <mat-option *ngFor="let item of courses$" [value]="item">{{item.title}}</mat-option>
        </mat-select>
    </mat-form-field>    
</div> -->
<div style="top: 0px; left: 0px; font-family: Verdana, Geneva, Tahoma, sans-serif; font-size: 13px;">
      
    <div style="padding-bottom: 4px;">
        <label>
            File Name:
            <input type="text" id="fileName" [(ngModel)]="fileName">
        </label>
        <label style="margin-left: 20px;">
            Separator
            <input type="text" style="width: 20px;" id="columnSeparator">
        </label>
        <label style="margin-left: 20px;">
            <button (click)="onBtnExport(fileName)">Export to CSV</button>
        </label>
        <button mat-icon-button color="primary" (click)="deleteReportRegalia()"><mat-icon>delete</mat-icon></button>
    </div>
</div>
<div>
    <ag-grid-angular
        style="width: 1024px; height: 680px"
        class="ag-theme-alpine"
        [rowData]="rowData"
        [columnDefs]="columnDefs"
        (gridReady)="onGridReady($event)"
        >
    </ag-grid-angular>   
</div>
</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button cdkFocusInitial (click)="filterRegaliaReport(selectedDate,selectedCourse)">Generate</button> <!--fromDate,toDate,--> <!--selectedDate,-->
</mat-dialog-actions>